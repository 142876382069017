import React, { useState } from "react";
import logo from '../../../assets/icons/icon-normativa.svg';
import iconMenu from '../../../assets/icons/icon-menu-mobile.svg';
import iconLogin from '../../../assets/icons/login-icon.svg';
import closeMenu from '../../../assets/icons/close-menuanchor.svg';
import "./Navigation.css";
import { Link, NavLink } from "react-router-dom";

export const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const isAuthenticated = sessionStorage.getItem('email') && sessionStorage.getItem('name') && sessionStorage.getItem('id');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <nav>
        <Link to="/" className="title">
          <img src={logo} alt="Logo" width="32px" height="32px" />
        </Link>
        <ul className={menuOpen ? "open" : ""}>
          <div className="seccion-uno-nav">
            <li>
              <NavLink to="/regulado" onClick={handleLinkClick}>Regulado</NavLink>
            </li>
            <li>
              <NavLink to="/especialistas" onClick={handleLinkClick}>Especialistas</NavLink>
            </li>
          </div>
          <div className="seccion-dos-nav">
            {isAuthenticated ? (
              <li className="desktop-only">
                <NavLink to="/logout" onClick={() => {
                  sessionStorage.clear(); 
                  window.location.reload();
                }} >
                  Cerrar Sesión
                  <img src={iconLogin} alt="login-icon" />
                </NavLink >
              </li>
            ) : (
              <li className="desktop-only">
                <NavLink to="/login" >
                  Ingresar
                </NavLink>
              </li>
            )}
               <li>
              <NavLink to="/registro" onClick={handleLinkClick} className="login-btn">Registrarse
              <img src={iconLogin} alt="login-icon" />
               </NavLink>
            </li>
          </div>
        </ul>
        <div className="menu-mobile">
          {isAuthenticated ? (
            <NavLink to="/login" onClick={() => {
              sessionStorage.clear(); 
              window.location.reload(); 
            }} className="login-btn mobile-only">
              Cerrar Sesión
              <img src={iconLogin} alt="login-icon" />
            </NavLink>
          ) : (
            <NavLink to="/login" className="login-btn mobile-only">
              Ingresar
              <img src={iconLogin} alt="login-icon" />
            </NavLink>
          )}
          <div className="menu" onClick={toggleMenu}>
            <img src={menuOpen ? closeMenu : iconMenu} alt={menuOpen ? "Cerrar menú" : "Icono del menú"} />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
