import React, { useState, useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './progressBar.css';

export const ProgressBarLoading = ({ onComplete, title, description }) => {
  const [now, setNow] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setNow((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          if (onComplete) {
            onComplete();
          }
          return 100;
        }
        return prev + 1;
      });
    }, 100);

    return () => clearInterval(interval);
  }, [onComplete]);

  return (
    <div className='progressBar-container'>
      <div className='progressBar-body'>
        <div className='progressBar-title'>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
        <ProgressBar now={now}  />
      </div>
    </div>
  );
};
