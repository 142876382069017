import React from 'react'
import './specialist.css'
import iconCode from '../../../assets/icons/code.svg'

export const Specialist = () => {
  return (
    <>
    <div className='specialist'>
       <div className='specialist-container'>
        <img src={iconCode} alt='icon-code' width='32px' height='32px'/>
         <p className='specialist-title'>Funcionalidad en desarrollo</p>
         <p className='specialist-subTitle' >Conoce a los especialista que resolverán tus requerimientos a través de los artículos</p>
       </div>
       <div className='specialist-footer'>
        <span>¿Eres un Especialista? <a href=''>Únete a nuestro directorio</a> para ofrecer tus servicios</span>
        </div>

    </div>
    </>
  )
}
