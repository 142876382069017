import React from 'react';
import Micropanel from '../components/utilities/MicroPanel/microPanel';

// Función para extraer los títulos del XML según las etiquetas especificadas
export const parseArticleTitles = (xmlString) => {
  const parser = new DOMParser();
  const xml = parser.parseFromString(xmlString, 'text/xml');
  const titles = [];

  // Añadir Encabezado
  const encabezado = xml.querySelector('Encabezado > Texto');
  if (encabezado) {
    titles.push({ title: encabezado.textContent.trim(), id: 'encabezado', type: 'encabezado' });
  }

  // Añadir Títulos y Artículos
  const titulos = xml.querySelectorAll('EstructuraFuncional[tipoParte="Título"]');
  titulos.forEach((titulo, index) => {
    const tituloTexto = titulo.querySelector('Texto').textContent.trim();
    titles.push({ title: tituloTexto, id: `titulo-${index + 1}`, type: 'titulo' });

    const articulos = titulo.querySelectorAll('EstructuraFuncional[tipoParte="Artículo"]');
    
    articulos.forEach((articulo, artIndex) => {
      const articuloTexto = articulo.querySelector('Texto').textContent.slice(0, 30).trim();
      const match = articuloTexto.match(/Artículo\s+(\d+º?(\s*\w+)?)/i);
      const articleTitle = match ? match[0] : `Artículo ${artIndex + 1}`;
      titles.push({
        title: articleTitle,
        id: `articulo-${index + 1}-${artIndex + 1}`,
        type: 'articulo',
        parentId: `titulo-${index + 1}`
      });
    });
  });

  // Añadir Promulgación
  const promulgacion = xml.querySelector('Promulgacion > Texto');
  if (promulgacion) {
    titles.push({ title: promulgacion.textContent.trim(), id: 'promulgacion', type: 'promulgacion' });
  }

  // Añadir Anexo
  const anexo = xml.querySelector('Anexos > Anexo > Metadatos > Titulo');
  if (anexo) {
    titles.push({ title: anexo.textContent.trim(), id: 'anexo', type: 'anexo' });
  }

  return titles;
};

// Función para eliminar etiquetas de imagen codificada
export const cleanImageLabels = (text) => {
  const regex = /[A-Za-z0-9-]+\.(jpeg|jpg|png|gif)[^ ]*/g;
  // return text.replace(regex, '');
  const textWithoutImages = text.replace(regex, '');

    // Eliminar comillas simples y dobles
  const textWithoutQuotes = textWithoutImages.replace(/["']/g, '');
  
  return textWithoutQuotes;
};

export const removeSpecificPatterns = (text) => {
  // Eliminar referencias a artículos como "Art. único", "Art. único, Nº 7", "Art. 19", "Art. 3º transitorio", "Art. 8º", "Nº 2", etc.
  const regexPatternArt = /\bArt\.?\s+único(?:,\s*Nº\s*\d+)?\s*$|\bArt\.?\s*(ÚNICO|[0-9]+º?(\s*\w+)?(?:,\s*Nº\s*\d+)?(\s*\w+)?)\s*$|\bArt\.?\s*(Primero|Segundo|Tercero|Cuarto|Quinto|Sexto|Séptimo|Octavo|Noveno|Décimo|[0-9]+º?)\s*$|\bNº\s*\d+\s*$/gim;

  // Eliminar referencias a leyes como "L. 19.759", "L. Y EL NÚMERO", etc.
  const regexPatternLaw = /\bL\.\s*\d+(\.\d+)?(\s*[º]?\s*\w+)?(\s*\w+)?\s*$/gim;

  // Aplicar la eliminación de ambos patrones
  return text.replace(regexPatternArt, '').replace(regexPatternLaw, '');
};



// Función para parsear el XML y convertirlo a elementos React
export const parseXmlToReact = (
  xmlString,
  idNorma,
  hoveredArticle,
  setHoveredArticle,
  isMicropanelHovered,
  setIsMicropanelHovered,
  fixedMicropanelIndex,
  setFixedMicropanelIndex
) => {
  const parser = new DOMParser();
  const xml = parser.parseFromString(xmlString, 'text/xml');
  let elements = [];

  // Procesar Encabezado
  const encabezado = xml.querySelector('Encabezado > Texto');
  if (encabezado) {
    elements.push(
      <div key="encabezado" id="encabezado" className="texto-articulo">
        <p>{encabezado.textContent.trim()}</p>
      </div>
    );
  }

  // Procesar Títulos y Artículos
  const estructurasFuncionales = xml.querySelectorAll('EstructuraFuncional');
  estructurasFuncionales.forEach((ef, index) => {
    const tipoParte = ef.getAttribute('tipoParte');
    const texto = ef.querySelector('Texto').textContent.trim();
    const articuloId = `estructura-${index}`;

    if (tipoParte === 'Título') {
      elements.push(
        <div key={`titulo-${index + 1}`} id={`titulo-${index + 1}`} className="texto-articulo">
          <h2>{texto}</h2>
        </div>
      );
    } else if (tipoParte === 'Artículo' || tipoParte === 'Artículo único') {
      let articuloTexto = removeSpecificPatterns(texto);
      const closeMicropanel = () => {
        setFixedMicropanelIndex(null);
        setHoveredArticle(null);
        setIsMicropanelHovered(false);
      };

      const isActive = fixedMicropanelIndex === articuloId;
      const articleClassName = `articulo-container ${hoveredArticle === articuloId || isActive ? 'highlight' : ''} ${isActive ? 'active' : ''}`;

      let articuloElements = [];
      const archivosBinarios = ef.querySelectorAll('ArchivoBinario');
      archivosBinarios.forEach((archivoBinario, binIndex) => {
        const tipoContenido = archivoBinario.querySelector('TipoContenido').textContent;
        const dataCodificada = archivoBinario.querySelector('DataCodificada').textContent;
        if (dataCodificada) {
          const imgUrl = `data:${tipoContenido};base64,${dataCodificada}`;
          articuloElements.push(
            <img key={`imagen-${articuloId}-${binIndex}`} src={imgUrl} style={{ maxWidth: '100%', height: 'auto' }} alt="Imagen de la norma" />
          );
        }
      });

      articuloElements.unshift(
        <p key={`texto-${articuloId}`}>{cleanImageLabels(articuloTexto)}</p>
      );

      elements.push(
        <div
          className="article-wrapper"
          id={articuloId}
          key={`estructura-wrapper-${articuloId}`}
          onMouseEnter={() => !isMicropanelHovered && !isActive && setHoveredArticle(articuloId)}
          onMouseLeave={() => !isMicropanelHovered && !isActive && setHoveredArticle(null)}
        >
          <div className={articleClassName}>
            {articuloElements}
            {(hoveredArticle === articuloId || isActive) && (
              <Micropanel
                content={articuloTexto ? articuloTexto : ''}
                onMouseEnter={() => setIsMicropanelHovered(true)}
                onMouseLeave={() => setIsMicropanelHovered(false)}
                setMicropanelActive={() => setFixedMicropanelIndex(articuloId)}
                closeMicropanel={closeMicropanel}
                index={articuloId}
                setHoveredArticle={setHoveredArticle}
                idNorma={idNorma}
              />
            )}
          </div>
        </div>
      );
    }
  });

  // Procesar Promulgación
  const promulgacion = xml.querySelector('Promulgacion > Texto');
  if (promulgacion) {
    elements.push(
      <div key="promulgacion" id="promulgacion" className="texto-articulo">
        <p>{promulgacion.textContent.trim()}</p>
      </div>
    );
  }

  // Procesar Anexo
  const anexo = xml.querySelector('Anexos > Anexo > Texto');
  if (anexo) {
    elements.push(
      <div key="anexo" id="anexo" className="texto-articulo">
        <p>{anexo.textContent.trim()}</p>
      </div>
    );
  }

  return elements;
};

// Función para renderizar el título y los organismos
export const renderTitleAndOrganisms = (xmlContent) => {
  const parser = new DOMParser();
  const xml = parser.parseFromString(xmlContent, 'text/xml');

  const decretoNorma = xml.querySelector('TipoNumero');
  const tituloNorma = xml.querySelector('TituloNorma');
  let organismoElements = [];
  const organismos = xml.querySelectorAll('Organismos > Organismo');
  if (organismos.length > 0) {
    organismos.forEach((organismo, index) => {
      organismoElements.push(
        <p key={`organismo-${index}`} className="organismo-norma">
          {organismo.textContent}
        </p>
      );
    });
  }

  if (tituloNorma) {
    return (
      <div className="titulo-container" key="titulo-container">
        <h1 key="titulo">
          <span key="decreto" style={{ textTransform: 'uppercase' }}>
            {decretoNorma ? decretoNorma.textContent + ' ' : ''}
          </span>
          {tituloNorma.textContent.charAt(0).toUpperCase() + tituloNorma.textContent.slice(1).toLowerCase()}
        </h1>
        {organismoElements.length > 0 && (
          <div key="organismos-container" className="organismos-container">
            {organismoElements}
          </div>
        )}
      </div>
    );
  }
  
  return null;
};

// Función para extraer los datos del título
export const extractTitleData = (xmlContent) => {
  const parser = new DOMParser();
  const xml = parser.parseFromString(xmlContent, 'text/xml');
  let tipo = xml.querySelector('TipoNumero > Tipo')?.textContent || '';
  tipo = tipo
    .replace('Decreto con Fuerza de Ley', 'DFL')
    .replace('Decreto', 'DTO')
    .replace('Resolución', 'RES')
    .replace('Ministerio', 'MIN.');

  const numero = xml.querySelector('TipoNumero > Numero')?.textContent || '';
  const fechaPromulgacion = xml.querySelector('Identificador')?.getAttribute('fechaPromulgacion') || '';
  const fechaVersion = xml.querySelector('Norma')?.getAttribute('fechaVersion') || '';
  const materiaNorma = xml.querySelector('Materias > Materia')?.textContent || '';
  const fechaPublicacion = xml.querySelector('Identificador')?.getAttribute('fechaPublicacion') || '';
  const tituloNorma = xml.querySelector('TituloNorma')?.textContent || '';
  const idNorma = xml.querySelector('Norma')?.getAttribute('normaId') || '';
  let organismo = xml.querySelector('Organismos > Organismo')?.textContent || '';

  // Reemplazar "Ministerio de" por "Min" insensiblemente a mayúsculas y minúsculas
  organismo = organismo.replace(/Ministerio/gi, 'MIN.');
  let encabezado = xml.querySelector('Encabezado > Texto')?.textContent || '';
  return { tipo, numero, encabezado, fechaPublicacion, fechaPromulgacion, tituloNorma, fechaVersion, materiaNorma, organismo };
};

// Función para extraer la meta descripción
export const extractMetaDescription = (xmlContent) => {
  const parser = new DOMParser();
  const xml = parser.parseFromString(xmlContent, 'text/xml');
  const estructuraFuncional = xml.querySelector('EstructurasFuncionales EstructuraFuncional Texto');
  const metaDescription = estructuraFuncional ? `${estructuraFuncional.textContent} | ARTICULO 1` : '';
  return metaDescription;
};