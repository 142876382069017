import axios from 'axios';
const urlApi = process.env.REACT_APP_URL_API;
const token = process.env.REACT_APP_USER_CREATE_TOKEN;

export const iaLoadNorm = async ({ idNorm,namespace}) => {
  const config = {
    method: 'POST',
    url: `${urlApi}assistant/load-norm`,
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json',
    },
    data: {
      idNorm: idNorm,
      namespace:namespace,
      chunkSize: 800,
      deleteBot:'false'
    },
  };

  try {
    const response = await axios(config);
    if (response.data) {
      return response.data;
    } else {
      throw new Error('La respuesta no contiene el campo taskId esperado');
    }
  } catch (error) {
    console.error('Hubo un problema al procesar la solicitud:', error.response ? error.response.data : error.message);
    throw error;
  }
};


export const iaChatNorm = async ({ userMessage, namespace }) => {
    const config = {
      method: 'POST', // Cambiar a POST
      url: `${urlApi}assistant/chat`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      data: {
        "userMessage": userMessage,
        "namespace": namespace,
        "instruction": "Eres un experto en el contenido del proyecto de ley y cómo fue avanzando en el tiempo utilizando para ello la información disponible con las fechas indicadas. Es importante que respondas al usuario poniéndole en contexto de que secciones del proyecto de ley obtuviste la información para tus respuestas. Tus respuestas deben ser claras, coherentes, asertivas y directas",
        "temperature": 0.5,
        "target": "norm"
      }
    };
  
    try {
      const response = await axios(config);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('La respuesta no contiene la explicación esperada');
      }
    } catch (error) {
      console.error('Hubo un problema al procesar la solicitud:', error.response ? error.response.data : error.message);
      throw error;
    }
  };