import React from 'react';
import './designCard.css';
import isotipo from '../../../assets/isotipo.svg';
import logoVerified from '../../../assets/icons/icon-verified.svg';
import iconTel from '../../../assets/icons/icon-phone.svg';
import iconEmail from '../../../assets/icons/icon-mail.svg';
import iconLink from '../../../assets/icons/icon-link.svg';

export const DesignCard = ({ uplFile = isotipo, name, phone, mail, web, description }) => {
  const title = name || "Normativa Legal";
  
  // Valor predeterminado para descriptionCard
  const defaultDescription = "Software dedicado al análisis, interpretación y ejecución de normativas legales chilenas con Inteligencia Artificial";

  const descriptionCard = Array.isArray(description) && description.length > 0 
  ? description[0].description // Mostrar solo la primera descripción del arreglo
  : defaultDescription;

  
  return (
    <div className='designCard'>
      <div className='design'>
        <div className="design-card-header">
          <div className="design-card-header-title">
            <img src={uplFile || isotipo} alt="logo Card" className='img-logo-card'/>
            <p>{title}</p> 
          </div>
          <div className='design-card-header-verified'>
            <img src={logoVerified} alt="logo verified"/>
            <p>Verificado</p>
          </div>
        </div>
        <div className='design-card-description'>
          <p>{descriptionCard}</p> 
        </div>
        <div className='design-card-footer'>
          <p>Contactar:</p>
          <div className='design-card-footer-contact'>
            <div className='contact-design'>
              <a href={`tel:${phone}`}> 
                <img src={iconTel} alt="telefono" />
              </a>
            </div>
            <div className='contact-design'>
              <a href={`mailto:${mail}`}> 
                <img src={iconEmail} alt="email" />
              </a>
            </div>
            <div className='contact-design'>
              <a href={web} target="_blank" rel="noopener noreferrer">
                <img src={iconLink} alt="enlace web" />
              </a>
            </div>
          </div>   
        </div>
      </div>
    </div>
  );
};