import axios from 'axios';

const token = process.env.REACT_APP_USER_CREATE_TOKEN;
const urlApi = process.env.REACT_APP_URL_API;

export const normContent = async (id) => {

  const config = {
    method: 'get',
    url:`${urlApi}normativa/norm-content/${id}`,
    headers: { 
      'Authorization': token,
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Hubo un problema al procesar la solicitud:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const encabezadoNorm = async (id) => {

  const config = {
    method: 'get',
    url:`${urlApi}normativa/header-norm/${id}`,
    headers: { 
      'Authorization': token,
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Hubo un problema al procesar la solicitud:', error.response ? error.response.data : error.message);
    throw error;
  }
};
