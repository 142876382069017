import './iaNormativa.css';
import iconIa from '../../../assets/icons/icon-ia-norm.svg';
import closeIa from '../../../assets/icons/close-menuanchor.svg';
import iconSend from '../../../assets/icons/send.svg';
import { iaLoadNorm, iaChatNorm } from '../../../services/iaNormativa';
import { useState, useEffect, useRef } from 'react';
import { ProgressBarLoading } from '../ProgressBar/progressBar';

export const IaNormativa = ({ idNorm }) => {
  const [showIaNorm, setShowIaNorm] = useState(false);
  const [showbtn, setShowBtn] = useState(true);
  const [chatMessages, setChatMessages] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); 
  const namespace = `norma_${idNorm}`;
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const handleIaNormativa = async () => {
    setShowIaNorm(true);
    setShowBtn(false);
    setLoading(true); 

    try {
      const data = await iaLoadNorm({ idNorm, namespace });
      if (data.status === 'exists' || data.status === 'created') {
      }
    } catch (error) {
      console.error('Error al cargar los datos de la normativa:', error);
      setError('Error al cargar los datos de la normativa.');
      setLoading(false); 
    }
  };

  const handleCloseIa = () => {
    setShowIaNorm(false);
    setShowBtn(true);    
  };

  const handleSendMessage = async () => {
    if (userMessage.trim() === '') return; 
    
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', message: userMessage }
    ]);
    setUserMessage('');
    
    setIsTyping(true);

    try {
      const response = await iaChatNorm({ userMessage, namespace });
      
      const assistantMessage = response.data?.content?.data || 'Respuesta no disponible';

      setChatMessages((prevMessages) => [
        ...prevMessages,
        { role: 'bot', message: assistantMessage }
      ]);
      setIsTyping(false);

    } catch (error) {
      console.error('Error al enviar el mensaje al chat:', error);
      setError(error.message);
      setIsTyping(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
  }, [chatMessages]);

  const handleProgressBarComplete = () => {
    setLoading(false);
    setChatMessages([
      { role: 'bot', message: '¡Hola! Soy tu Asistente IA en la Normativa. Cuéntame en qué te puedo ayudar.' }
    ]);
  };

  return (
    <>
      {showbtn && (  
        <button
          className='btn-iaNormativa'
          onClick={handleIaNormativa}
        >
          <img src={iconIa} alt="icon ia" /> BOT Normativa
        </button>
      )}

      {showIaNorm && ( 
        <div className={`ia-normativa ${loading ? 'loading' : ''}`}>
          <div className='ia-normativa-container'>
            <div className='ia-header'>
              <h4>Asistente IA Normativa</h4>
              <button onClick={handleCloseIa} className='close-iaNorm'>
                <img src={closeIa} alt='icon close ia'/>
              </button>
            </div>

            {loading ? (
              <ProgressBarLoading
                title="Creando IA Normativa"
                description="Cargando la Normativa a la IA"
                onComplete={handleProgressBarComplete} 
              />
            ) : (
              <>
                <div className="chat-messages-norm">
                  {chatMessages.map((msg, index) => (
                    <div key={index} className={`chat-message-norm ${msg.role}`}>
                      <p>{msg.message}</p>
                    </div>
                  ))}
                  {isTyping && (
                    <div className="chat-typing-norm-ia" ref={messagesEndRef}>
                      <div className='chat-typing-container-ia'>
                        <p>Escribiendo...</p>
                      </div>
                    </div>
                  )}
                </div>

                <form onSubmit={(e) => e.preventDefault()} className='form-iaNorm'>
                  <input
                    disabled={isTyping}
                    type="text"
                    value={userMessage}
                    onChange={(e) => setUserMessage(e.target.value)}
                    placeholder="Envía tu mensaje..."
                    className="chat-input-iaNorm"
                    onKeyPress={handleKeyPress}
                  />
                  <button className="chat-send-button" onClick={handleSendMessage} disabled={isTyping}>
                    <img src={iconSend} alt="Send" />
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      )}
      {error && <div className='error-message'>{error}</div>}
    </>
  );
};

export default IaNormativa;
