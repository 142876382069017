import axios from 'axios';

const token = process.env.REACT_APP_USER_CREATE_TOKEN;
const apiUrl = process.env.REACT_APP_URL_API;
export const lastLaw = async () => {

  const config = {
    method: 'get',
    url: `${apiUrl}normativa/last-law/5`,
    headers: { 
      'Authorization': token,
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Hubo un problema al procesar la solicitud:', error.response ? error.response.data : error.message);
    throw error;
  }
};
