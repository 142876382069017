// import React, { useState } from 'react';
// import Card from 'react-bootstrap/Card';
// import CardGroup from 'react-bootstrap/CardGroup';
// import Button from 'react-bootstrap/Button';
// import mpExplicacion from '../../../assets/mp-explicacion.svg';
// import mpPregunta from '../../../assets/mp-pregunta.svg';
// import mpEspecialista from '../../../assets/mp-especialista.svg';
// import arrowRight from '../../../assets/icons/arrow-right.svg';
// import arrowLeft from '../../../assets/icons/arrow-left.svg';
// import './tutorialMp.css';

// const cardsData = [
//   {
//     id: 'ONE',
//     img: mpExplicacion,
//     title: 'Entiende cada artículo',
//     description: 'Recibe una explicación detallada sobre el artículo elegido. Nuestra IA proporciona información clara para facilitar tu comprensión.',
//     step: '1/3 funciones',
//   },
//   {
//     id: 'TWO',
//     img: mpPregunta,
//     title: 'Resuelve tus dudas',
//     description: 'Haz preguntas directamente sobre el artículo normativo. Obtén respuestas rápidas y precisas en tiempo real para resolver todas tus dudas.',
//     step: '2/3 funciones',
//   },
//   {
//     id: 'THREE',
//     img: mpEspecialista,
//     title: 'Contacta con expertos',
//     description: 'Conéctate con especialistas según el artículo normativo. Encuentra lo necesario para cumplir con los requerimientos normativos.',
//     step: '3/3 funciones',
//   },
// ];

// const TutorialMp = () => {
//   const [selectedCardIndex, setSelectedCardIndex] = useState(0);
//   const [isTutorialFinished, setIsTutorialFinished] = useState(false);
//   const [isModalVisible, setIsModalVisible] = useState(true);

//   const nextCard = () => {
//     if (selectedCardIndex < cardsData.length - 1) {
//       setSelectedCardIndex((prevIndex) => (prevIndex + 1) % cardsData.length);
//     } else {
//       setIsTutorialFinished(true);
//     }
//   };

//   const backCard = () => {
//     setSelectedCardIndex((prevIndex) => (prevIndex - 1 + cardsData.length) % cardsData.length);
//   };

//   const finishTutorial = () => {
//     setIsTutorialFinished(true);
//   };

//   if (isTutorialFinished) {
//     return null; 
//   }

//   return (
//     <div className={`modal-overlay ${isModalVisible ? 'visible' : 'hidden'}`}>
//       <div className='modal-container'>
//       <CardGroup>
//         <Card className='individual-card'>
//           <Card.Body>
//             <div className='body-card-tutorial'>
//               <img src={cardsData[selectedCardIndex].img} alt={cardsData[selectedCardIndex].title} />
//             </div>
//           </Card.Body>
//           <Card.Footer>
//             <div className='title-footer'>
//               <h4>{cardsData[selectedCardIndex].title}</h4>
//               <p>{cardsData[selectedCardIndex].description}</p>
//             </div>
//             <div className='steps'>
//               <span>{cardsData[selectedCardIndex].step}</span>

//               <div className='steps-btn'>
//                 {selectedCardIndex > 0 && (
//                   <button onClick={backCard}>
//                     <img src={arrowLeft} alt="Back" />
//                   </button>
//                 )}
//                 {selectedCardIndex < cardsData.length - 1 ? (
//                   <button onClick={nextCard}>
//                     <img src={arrowRight} alt="Next" />
//                   </button>
//                 ) : (
//                   <p onClick={finishTutorial} className='finish-btn'>
//                     Finalizar
//                   </p>
//                 )}
//               </div>
//             </div>
//           </Card.Footer>
//         </Card>
//       </CardGroup>
//     </div>
//   </div> 
//   );
// };

// export default TutorialMp;


import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import mpExplicacion from '../../../assets/mp-explicacion.svg';
import mpPregunta from '../../../assets/mp-pregunta.svg';
import mpEspecialista from '../../../assets/mp-especialista.svg';
import arrowRight from '../../../assets/icons/arrow-right.svg';
import arrowLeft from '../../../assets/icons/arrow-left.svg';
import './tutorialMp.css';

const cardsData = [
  {
    id: 'ONE',
    img: mpExplicacion,
    title: 'Entiende cada artículo',
    description: 'Recibe una explicación detallada sobre el artículo elegido. Nuestra IA proporciona información clara para facilitar tu comprensión.',
    step: '1/3 funciones',
  },
  {
    id: 'TWO',
    img: mpPregunta,
    title: 'Resuelve tus dudas',
    description: 'Haz preguntas directamente sobre el artículo normativo. Obtén respuestas rápidas y precisas en tiempo real para resolver todas tus dudas.',
    step: '2/3 funciones',
  },
  {
    id: 'THREE',
    img: mpEspecialista,
    title: 'Contacta con expertos',
    description: 'Conéctate con especialistas según el artículo normativo. Encuentra lo necesario para cumplir con los requerimientos normativos.',
    step: '3/3 funciones',
  },
];

const TutorialMp = () => {
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [isTutorialFinished, setIsTutorialFinished] = useState(false);

  useEffect(() => {
    const tutorialDone = localStorage.getItem('tutorialDone');
    setIsTutorialFinished(tutorialDone);
  }, []);

  const nextCard = () => {
    if (selectedCardIndex < cardsData.length - 1) {
      setSelectedCardIndex((prevIndex) => (prevIndex + 1) % cardsData.length);
    } else {
      finishTutorial();
    }
  };

  const backCard = () => {
    setSelectedCardIndex((prevIndex) => (prevIndex - 1 + cardsData.length) % cardsData.length);
  };

  const finishTutorial = () => {
    setIsTutorialFinished(true);
    localStorage.setItem('tutorialDone', 'true');
  };

  if (isTutorialFinished) {
    return null; 
  }

  return (
    <div className='modal-overlay visible'>
      <div className='modal-container'>
      <CardGroup>
        <Card className='individual-card'>
          <Card.Body>
            <div className='body-card-tutorial'>
              <img src={cardsData[selectedCardIndex].img} alt={cardsData[selectedCardIndex].title} />
            </div>
          </Card.Body>
          <Card.Footer>
            <div className='title-footer'>
              <h4>{cardsData[selectedCardIndex].title}</h4>
              <p>{cardsData[selectedCardIndex].description}</p>
            </div>
            <div className='steps'>
              <span>{cardsData[selectedCardIndex].step}</span>

              <div className='steps-btn'>
                {selectedCardIndex > 0 && (
                  <button onClick={backCard}>
                    <img src={arrowLeft} alt="Back" />
                  </button>
                )}
                {selectedCardIndex < cardsData.length - 1 ? (
                  <button onClick={nextCard}>
                    <img src={arrowRight} alt="Next" />
                  </button>
                ) : (
                  <p onClick={finishTutorial} className='finish-btn'>
                    Finalizar
                  </p>
                )}
              </div>
            </div>
          </Card.Footer>
        </Card>
      </CardGroup>
    </div>
  </div> 
  );
};

export default TutorialMp;
