import React from 'react';
import './dataProject.css';

export const TramitacionProject = ({ tramitacion, onVotacionClick }) => {
  return (
    <div className='table-project-container'>
      <table className="table">
        <thead>
          <tr>
            <th className="titles-col" scope="col">Sesión/Leg</th>
            <th className="titles-col" scope="col">Fecha</th>
            <th className="titles-col-etapa" scope="col">Etapa</th>
            <th className="titles-col-subetapa" scope="col">Subetapa</th>
            <th className="titles-col" scope="col">Documento</th>
          </tr>
        </thead>
        <tbody className="row-data table-hover">
          {tramitacion.map((item, index) => (
            <tr key={index}>
              <td className="titles-row" scope="row">{item.session}</td>
              <td className="titles-row">{item.date}</td>
              <td className="titles-row">{item.phase}</td>
              <td className="titles-row">{item.subPhase}</td>
              <td className="titles-links">
                {item.documents && item.documents.map((doc, docIndex) => {
                  const children = doc.children[0]; 
                  const attributes = doc.attributes[0]; 

                  let href = '';

                  if (children === "Votación") {
                    return (
                      <a key={docIndex} href="#" onClick={(e) => { 
                        e.preventDefault(); 
                        onVotacionClick(attributes.detail);
                      }}>
                        {children}
                      </a>
                    );
                  } else if (children === "Video") {
                    href = attributes;
                  } else if (children === "Diario") {
                    // Si el href debe ser solamente attributes
                    href = attributes.includes('camara.cl') ? attributes : `https://tramitacion.senado.cl/${attributes}`;
                  } else {
                    href = `https://tramitacion.senado.cl/${attributes}`;
                  }

                  return (
                    <a key={docIndex} href={href} target="_blank" rel="noopener noreferrer" download>
                      {children}
                    </a>
                  );
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TramitacionProject;
