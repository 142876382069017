import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createUser } from '../../../services/createUser';
import { useNavigate } from 'react-router-dom';
import './registerForm.css';
import logo from '../../../assets/icons/icon-normativa.svg'; 
import googleIcon from '../../../assets/icons/icon-google.svg'
import { validationSchema } from '../AuthValidation';
import eyeOff from '../../../assets/icons/eye-off.svg'
import eyeOn from '../../../assets/icons/eye-onn.svg'
import help from '../../../assets/icons/help-circle.svg';


const RegisterForm = () => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema), 
  });

  useEffect(() => {
    const savedData = sessionStorage.getItem('formData');
    if (savedData) {
      reset(JSON.parse(savedData));
    }
  }, [reset]);

  const onSubmit = async (data) => {
    try {
      const userData = await createUser(data.email, data.password);
      setSuccessMessage('Usuario registrado correctamente');
      sessionStorage.removeItem('formData');
      reset();
      navigate('/registro-especialista');
    } catch (error) {
      console.error('Error al crear usuario:', error);
      setErrorMessage(`Hubo un error: ${error.message}`);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="login-container">
      <div className="left-side">
        <div className="logo">
          <img src={logo} alt='logo normativa'/>
        </div>
        <div className="description-register">
          <h3 className="">Simplificando tu acceso a las Normativas Legales con IA</h3>
          <p className="">Normativa Legal utiliza IA para ofrecerte un buscador eficiente, explicaciones claras y conexión con especialistas. Facilita tu cumplimiento normativo y mantente al día con las leyes.</p>
        </div>
      </div>
      <div className="right-side">
        <div className='form-header-register'>
          <h2 style={{ color: '#454545' }}>Registro de usuario</h2>
          <button className="google-register-button">
            <img src={googleIcon} alt='icon-google'/>
            Continuar con Google
          </button>
          <div className="separator"><span>O</span></div>
        </div>
        <form className='form-body-register' onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group-register">
            <label htmlFor="email">Correo electrónico</label>
            <input
              type="email"
              id="email"
              placeholder="Correo electrónico"
              {...register('email')}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errors.email && <span className="error-message-form">{errors.email.message}</span>}
          </div>
          <div className="form-group-register">
            <label htmlFor="password">Contraseña  
              <div className="icon-help-pass">
              <img src={help} alt="icon-help" />
              <span className="tooltip-text">La contraseña debe contener mayúscula, números y al menos un carácter especial (@$!%*?&._)</span>
            </div></label>
            <div className="input-pass-container">
            <input
              type={passwordVisible ? "text" : "password"} 
              id="password"
              placeholder="Contraseña"
              {...register('password')}
            />
            <img 
              src={passwordVisible ? eyeOn : eyeOff} 
              alt={passwordVisible ? 'Mostrar contraseña' : 'Ocultar contraseña'} 
              onClick={togglePasswordVisibility} 
              className="toggle-password-visibility-register" 
            />
            </div>
            {errors.password && <span className="error-message-form">{errors.password.message}</span>}
          </div>
          <div className="form-group-register">
            <label htmlFor="confirmPassword">Confirmar contraseña</label>
            <div className="input-pass-container">
            <input
              type={passwordVisible ? "text" : "password"}
              id="confirmPassword"
              placeholder="Confirmar contraseña"
              {...register('confirmPassword')}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img 
              src={passwordVisible ? eyeOn : eyeOff} 
              alt={passwordVisible ? 'Mostrar contraseña' : 'Ocultar contraseña'} 
              onClick={togglePasswordVisibility} 
              className="toggle-password-visibility-register" 
            />
            </div>
            {errors.confirmPassword && <span className="error-message-form">{errors.confirmPassword.message}</span>}
          </div>
          <button type="submit" className="register-button">Registrarme</button>
        </form>
        <p style={{color: '#454545'}} className='link-login'>
          ¿Ya tienes cuenta? <a className="links-text" href="/login">Iniciar sesión</a>
        </p>
      </div>
    </div>
  );
};

export default RegisterForm;
