import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './loginForm.css'; 
import logoForm from '../../../assets/icons/icon-normativa.svg'
import googleIcon from '../../../assets/icons/icon-google.svg'
import eyeOff from '../../../assets/icons/eye-off.svg'
import eyeOn from '../../../assets/icons/eye-onn.svg'
import { loginUser } from '../../../services/loginUser';

const LoginForm = () => {

  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); 
  const [password, setPassword] = useState('');

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const userData = await loginUser(email, password);
      if (userData && userData.data._id) {
        navigate('/');
     sessionStorage.setItem('name', userData.data.name);
     sessionStorage.setItem('email', userData.data.email);
     sessionStorage.setItem('id', userData.data._id);
       
      }else if (userData.data.message === "invalid password") {
        setErrorMessage('Contraseña incorrecta');
      } else if (userData.data.message === `User with email '${email}' not found`) {
        setErrorMessage('El correo no se encuentra registrado');
      } else {
        setErrorMessage(''); 
      }
    } 
    catch (error) {
      console.error('Error de inicio de sesión:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="left-side">
        <div className="logo"></div>
        <div className="description-login">
          <h3>Simplificando tu acceso a las Normativas Legales con IA</h3>
          <p>Normativa Legal utiliza IA para ofrecerte un buscador eficiente, explicaciones claras y conexión con especialistas. Facilita tu cumplimiento normativo y mantente al día con las leyes.</p>
        </div>
      </div>
      <div className="right-side-login">

        <div className='login-form'>
          <div className='form-header-login'>
            <h3>¡Bienvenido de vuelta!</h3> 
            <button className="google-login-button">
              <img src={googleIcon} alt='icon-google'/>
              Continuar con Google
            </button>
            <div className="separator"><span>O</span></div>
          </div>
          <form className='form-body-login' onSubmit={handleSubmit}>
            <div className="form-group-login">
              <label htmlFor="email">Correo electrónico</label>
              <input 
                type="email" 
                id="email" 
                placeholder="Correo electrónico" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required 
              />
            </div>
            <div className="form-group-login">
              <label htmlFor="password">Contraseña</label>
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <img 
                src={passwordVisible ? eyeOn : eyeOff} 
                alt={passwordVisible ? 'Mostrar contraseña' : 'Ocultar contraseña'} 
                onClick={togglePasswordVisibility} 
                className="toggle-password-visibility" 
              />
            </div>
            {errorMessage && <p className="error-message-auth">{errorMessage}</p>}
            <div className="form-actions">
              <div>
                <input type="checkbox" id="remember-me" />
                <label htmlFor="remember-me">Recuérdame</label>
              </div>
              <a href="/restore">Restablecer contraseña</a>
            </div>
            <button type="submit" className="login-button" >Iniciar sesión</button>
          </form>
          <p style={{color: '#454545'}}>
            ¿No tienes cuenta? <a className="links-text" href="/registro">Regístrate</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;