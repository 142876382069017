import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import './dropdown.css';

const CustomDropdown = ({ pageSize, handlePageSizeChange }) => {
  return (
    <DropdownButton id="dropdown-basic-button" title={` ${pageSize}`} className='btn-dropdown'>
      <Dropdown.Item onClick={() => handlePageSizeChange(10)}>10</Dropdown.Item>
      <Dropdown.Item onClick={() => handlePageSizeChange(25)}>25</Dropdown.Item>
      <Dropdown.Item onClick={() => handlePageSizeChange(50)}>50</Dropdown.Item>
    </DropdownButton>
  );
}

export default CustomDropdown;
