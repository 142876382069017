import axios from 'axios';

const token = process.env.REACT_APP_USER_CREATE_TOKEN;
const apiUrl = process.env.REACT_APP_URL_API;

export const specialistList = async (idNorm) => {
  const data = JSON.stringify({
    idNorm
  });
  
  const config = {
    method: 'post',
    url: `${apiUrl}specialist/list`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    alert(`Error al encontrar especialistas: ${errorMessage}`);
    throw error;
  }
};
