import axios from 'axios';

const token = process.env.REACT_APP_USER_CREATE_TOKEN;
const apiUrl = process.env.REACT_APP_URL_API;

export const loginUser = async (email, password) => {
  const data = JSON.stringify({
    email,
    password,
  });

  const config = {
    method: 'post',
    url: `${apiUrl}user`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {

    if (error.response && error.response.status === 401) {
      alert('DATOS INCORRECTOS');
    } else {

        const errorMessage = error.response
        ? error.response.data.message || 'Error desconocido en el servidor'
        : error.message;
      alert(`Error al iniciar sesión: ${errorMessage}`);
    }
    throw error;
  }
};

