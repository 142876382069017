import React from 'react'
import { Helmet } from 'react-helmet';
import './notFound.css'

const NotFound = () => {
  return (
    <div className='notfound'>
      <Helmet>
        <title>
        PAGINA NO ENCONTRADA
        </title>
        <meta name="description" />
      </Helmet>
      <div className='title-notfound'>
      <h1>Error 404</h1>
      <h2>Página no encontrada</h2>
      <p>Lo sentimos, pero la página que estás buscando no existe. Puede que haya sido eliminada, que la URL esté incorrecta o que haya cambiado de lugar.</p>
      </div>
      
      <button><a href='/'> Volver al inicio</a></button>
       
    </div>
  )
}

export default NotFound