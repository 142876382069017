import React, { useState, useEffect, useRef } from 'react';
import './chatProject.css';
import iconSend from '../../assets/icons/send.svg';
import { iaChatAssistant } from '../../services/iaChatProject';

export const ChatProject = ({ uniqueId: initialUniqueId }) => { 
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const [uniqueId, setUniqueId] = useState(initialUniqueId);


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, sender: 'user' }]);
      setInput('');
      setIsTyping(true);

      try {
        const chatResponse = await iaChatAssistant({
          userMessage: input,
          uniqueId
        });

        if (chatResponse.data.uniqueId) {
          setUniqueId(chatResponse.data.uniqueId);
          console.log(chatResponse.data.uniqueId)
        }

      const responseText = chatResponse.data.content || "Respuesta no disponible";
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: responseText, sender: 'bot' }
      ]);
        
        setIsTyping(false);
      } catch (error) {
        console.error('Error al enviar la solicitud a la IA:', error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: 'Error al enviar la solicitud a la IA', sender: 'bot' }
        ]);
        setIsTyping(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className='chat-ia'>
      <div className="chat-container">
        <div className="chat-header">
          <p>¡Hola! Soy tu Asesor IA en Proyectos de Ley. Cuéntame en qué te puedo ayudar</p>
        </div>

        <div className="chat-messages">
          {messages.map((message, index) => (
            <div key={index} className={`chat-message ${message.sender}`}>
              <p>{typeof message.text === 'object' ? JSON.stringify(message.text.content) : message.text}</p>
           </div>
          ))}
          {isTyping && (
            <div className="chat-typing">
              <p>Escribiendo...</p> 
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="chat-input-container">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Envía tu mensaje..."
            className="chat-input"
            disabled={isTyping}
          />
          <button onClick={handleSend} className="chat-send-button" disabled={isTyping}>
            <img src={iconSend} alt="Send" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatProject;
