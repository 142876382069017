
import axios from 'axios';
const urlApi = process.env.REACT_APP_URL_API;
const token = process.env.REACT_APP_USER_CREATE_TOKEN;
const assistantName = `asistente${Math.floor(Math.random() * 10000)}`;

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const iaChatDocument = async ({ allProjectsData, deleteBot = false }) => {
  console.log(allProjectsData);  

  // allProjectsData.forEach(async (project) => {
    const config = {
      method: 'POST',
      url: `${urlApi}assistant/senado-documents/${assistantName}`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      data: {
        "namespace": assistantName,
        "chunkSize": 1000,
        "boletinId":"15140-15",  
        "deleteBot": deleteBot
      }
    };

    try {
      const postResponse = await axios(config);
      if (postResponse.data) {
        const taskId = postResponse.data.taskId;
        const getUrl = `${urlApi}tasks/${taskId}/${assistantName}`;
        console.log(getUrl);
        let getResponse;
        let state;
        do {
          await delay(2000);
          getResponse = await axios.get(getUrl, {
            headers: {
              'Authorization': token
            }
          });
          state = getResponse.data.state;
          console.log(`Polling state: ${state}`);
        } while (state !== 'completed');

        console.log(getResponse);
      } else {
        throw new Error('La respuesta no contiene el campo taskId esperado');
      }
    } catch (error) {
      console.error('Hubo un problema al procesar la solicitud:', error.response ? error.response.data : error.message);
      throw error;
    }
  };



export const iaChatAssistant = async ({ userMessage }) => {
  const config = {
    method: 'POST',
    url: `${urlApi}assistant/chat`,
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json',
    },
    data: {
      "userMessage": userMessage,
      "namespace": assistantName,
      "instruction": "Eres un experto en el contenido del proyecto de ley y cómo fue avanzando en el tiempo utilizando para ello la información disponible con las fechas indicadas. Es importante que respondas al usuario poniéndole en contexto de que secciones del proyecto de ley obtuviste la información para tus respuestas. Tus respuestas deben ser claras, coherentes, asertivas y directas.",
      "temperature": 0.5
    }
  };

  try {
    const response = await axios(config);
    if (response.data && response.data.taskId) {
      const taskUrl = `${urlApi}tasks/${response.data.taskId}/${assistantName}`;
      let taskResponse;
      
      while (true) {
        taskResponse = await axios.get(taskUrl, {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          }
        });
        
        if (taskResponse.data && taskResponse.data.state === 'completed') {
          console.log(taskResponse)

          return taskResponse.data;
        }
        
        await delay(2000); 
      }
    } else {
      throw new Error('La respuesta no contiene el campo taskId esperado');
    }
  } catch (error) {
    console.error('Hubo un problema al procesar la solicitud:', error.response ? error.response.data : error.message);
    throw error;
  }
};