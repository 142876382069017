import axios from 'axios';

const urlApi = process.env.REACT_APP_URL_API;
const token = process.env.REACT_APP_USER_CREATE_TOKEN;


export const createUser = async (name, email, password) => {
    const data = JSON.stringify({
    name: name,
    email: email,
    password: password
  });

  const config = {
    method: 'post',
    url: `${urlApi}user/create`,
    headers: { 
      'Authorization': token, 
      'Content-Type': 'application/json'
    },
    data: data
  };

  try {
    const response = await axios(config);
    return response.data;
  }catch (error) {
    if (error.response) {
        console.error('Error response:', error.response.data);
    } else if (error.request) {
        console.error('Error request:', error.request);
    } else {
        console.error('Error message:', error.message);
    }
    throw error;
}

};
