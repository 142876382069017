// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.explication-container {
  background: white;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 320px; */
  align-items: flex-start;
  border-bottom-right-radius: 6px;
  width: 100%;
  border-bottom-left-radius: 6px;
  /* max-height: 320px; */
}
  .explication-container.centered {
    justify-content: center;
    align-items: center; 
    min-height: 320px; 
  }
  
  .explication-container.flex-start {
    justify-content: flex-start;
    max-height: 320px;
    overflow: auto;
}
  .explication-container.flex-start span{
    display: block;
    width: 100%;
    text-align: right;
    color: #888;
    font-size: 14px;
    padding: 16px;
  }
.explication-footer span{
    color: rgba(136, 136, 136, 1)
}
.progress-container p{
    padding: 0 !important;
}

@media (max-width: 768px) {
    .explication-container {
        min-height: 320px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/utilities/NormResume/normResume.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,uBAAuB;EACvB,+BAA+B;EAC/B,WAAW;EACX,8BAA8B;EAC9B,uBAAuB;AACzB;EACE;IACE,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,2BAA2B;IAC3B,iBAAiB;IACjB,cAAc;AAClB;EACE;IACE,cAAc;IACd,WAAW;IACX,iBAAiB;IACjB,WAAW;IACX,eAAe;IACf,aAAa;EACf;AACF;IACI;AACJ;AACA;IACI,qBAAqB;AACzB;;AAEA;IACI;QACI,iBAAiB;QACjB,8BAA8B;QAC9B;IACJ;AACJ","sourcesContent":[".explication-container {\n  background: white;\n  padding: 0px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  /* min-height: 320px; */\n  align-items: flex-start;\n  border-bottom-right-radius: 6px;\n  width: 100%;\n  border-bottom-left-radius: 6px;\n  /* max-height: 320px; */\n}\n  .explication-container.centered {\n    justify-content: center;\n    align-items: center; \n    min-height: 320px; \n  }\n  \n  .explication-container.flex-start {\n    justify-content: flex-start;\n    max-height: 320px;\n    overflow: auto;\n}\n  .explication-container.flex-start span{\n    display: block;\n    width: 100%;\n    text-align: right;\n    color: #888;\n    font-size: 14px;\n    padding: 16px;\n  }\n.explication-footer span{\n    color: rgba(136, 136, 136, 1)\n}\n.progress-container p{\n    padding: 0 !important;\n}\n\n@media (max-width: 768px) {\n    .explication-container {\n        min-height: 320px;\n        border-bottom-left-radius: 8px;\n        border-bottom-right-radius: 8px\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
