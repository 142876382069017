// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header{
    border-bottom: 0.2px solid rgba(231, 231, 231, 1);
    border-bottom: 0.2px solid rgba(231, 231, 231, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 100%;   
    background: white;
    z-index: 9999;
    top: 0;
}

@media (max-width: 1000px) {
    .header{
       /* height: 200px; */
       margin: 0;
    }
    
    }`, "",{"version":3,"sources":["webpack://./src/components/Layout/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;IACjD,iDAAiD;IACjD,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,MAAM;AACV;;AAEA;IACI;OACG,mBAAmB;OACnB,SAAS;IACZ;;IAEA","sourcesContent":[".header{\n    border-bottom: 0.2px solid rgba(231, 231, 231, 1);\n    border-bottom: 0.2px solid rgba(231, 231, 231, 1);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    position: fixed;\n    width: 100%;   \n    background: white;\n    z-index: 9999;\n    top: 0;\n}\n\n@media (max-width: 1000px) {\n    .header{\n       /* height: 200px; */\n       margin: 0;\n    }\n    \n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
