import React from 'react'
import { Routes,Route,Navigate} from 'react-router-dom';
import Home from '../pages/Home/Home';
import RegisterForm from '../../src/components/Auth/Register/registerForm';
import LoginForm from '../components/Auth/Login/loginForm';
import Contacto from '../pages/Contact/contacto';
import NormContentPage from '../pages/NormContent/normContentPage';
import RestorePass from '../../src/components/Auth/RestorePass/restorePass';
import NotFound from '../pages/NotFound/notFound';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contacto" element={<Contacto />} />
      <Route path="/restore" element={<RestorePass />} />
      <Route path="/chile/:idNorma" element={<NormContentPage />} />
      <Route path="/register" element={<RegisterForm />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="*" element={<NotFound />} /> 
      <Route path="/decreto-ds19-subsidio-habitacional-texto-completo" element={<Navigate replace to="/chile/1092547" />} />
      <Route path="/ley-del-mono-chile" element={<Navigate replace to="/chile/1087285" />} />
      <Route path="/codigo-del-trabajo-chileno-explicado-actualizado" element={<Navigate replace to="/chile/207436" />} />
      <Route path="/ley-18216-sanciones-alternativas-chile" element={<Navigate replace to="/chile/29636" />} />
      <Route path="/ley-16744-accidentes-trabajo-enfermedades-profesionales" element={<Navigate replace to="/chile/28650" />} />
    </Routes>
  );
}
