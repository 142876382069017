import React, { useState, useEffect, useRef } from 'react';
import { NormResume } from '../NormResume/normResume';
import { NormQuestion } from '../NormQuestion/normQuestion';
import iconExpl from '../../../assets/icons/icon-explicacion.svg';
import iconQuestion from '../../../assets/icons/icon-question.svg';
import iconEspecialista from '../../../assets/icons/icon-especialista.svg';
import iconClose from '../../../assets/icons/close-micropanel.svg';
import iconDropdown from '../../../assets/icons/dropdown-micropanel.svg';
import iconCopy from '../../../assets/icons/copy.svg'
import iconShare from '../../../assets/icons/share-white.svg'
import './microPanel.css';

const Micropanel = ({ content,onMouseEnter, idNorma,closeMicropanel, onMouseLeave,setFixedMicropanelIndex,setIsMicropanelHovered,
  setMicropanelActive, index, setHoveredArticle }) => {
  const [showResume, setShowResume] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [showSpecialist, setShowSpecialist] = useState(false);
  const [btnPanelActive, setBtnPanelActive] = useState('');
  const [previousPanel, setPreviousPanel] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [explication, setExplication] = useState('');
  const [questionUser, setQuestionUser] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [isDropdownClicked, setIsDropdownClicked] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const panelRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (btnPanelActive && panelRef.current) {
      panelRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [btnPanelActive]);

  useEffect(() => {
    if (isMobile) {
      setBtnPanelActive('resume');  
    }
  }, [isMobile]);

  const handlePanelInteraction = (panelType) => {
    setShowResume(panelType === 'resume');
    setShowQuestion(panelType === 'question');
    setShowSpecialist(panelType === 'specialist');
    setBtnPanelActive(panelType);
    setMicropanelActive(index);
    setPreviousPanel(panelType);
  };



  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
    setIsDropdownClicked(prevState => !prevState); // Cambia el estado aquí
    if (!isDropdownOpen) {
      if (previousPanel === 'resume') {
        setShowResume(true);
      } else if (previousPanel === 'question') {
        setShowQuestion(true);
      } else if (previousPanel === 'especialist') {
        setShowSpecialist(true);
      }
    } else {
      setShowResume(false);
      setShowQuestion(false);
      setShowSpecialist(false);
    }
  };


  const handleCopy = (content) => {
    if (typeof content !== 'string') {
      console.error('El contenido no es una cadena de texto:', content);
      return;
    }
  
    navigator.clipboard.writeText(content).then(() => {
      setCopySuccess(true); 
      setTimeout(() => setCopySuccess(false), 2000); 
    }).catch((error) => {
      console.error('Error al copiar al portapapeles: ', error);
    });
  }
  
  const handleCopyClick = (event) => {
    event.preventDefault(); 
    handleCopy(content); 
  }
  
  const handleShare = () => {
    const articleId = index;  // Usar el index como id del artículo
    const urlToShare = `https://normativa.legal/chile/${idNorma}#${articleId}`;
    const textToShare = "Normativa Legal "; 
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(textToShare)}${encodeURIComponent(urlToShare)}`;
    window.open(whatsappUrl, '_blank');
  };
  

  return (
  <>
    <div ref={panelRef} className={`microPanel ${btnPanelActive ? 'active-MicroPanel' : ''}`}>
      <div className={`microPanel-container ${btnPanelActive ? 'active-MicroPanel-container' : ''}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div className={`btns-header-options-left ${btnPanelActive ? 'no-border-right' : ''}`}>
          <button className={`btn-panel ${btnPanelActive === 'resume' ? 'active-btnPanel' : ''}`} onClick={() => handlePanelInteraction('resume')}>
            <img src={iconExpl} alt='icon explicacion'/> Explicación
          </button>
          <button className={`btn-panel ${btnPanelActive === 'question' ? 'active-btnPanel' : ''}`} onClick={() => handlePanelInteraction('question')}>
            <img src={iconQuestion} alt='icon pregunta'/> Pregunta
          </button>
          {/* <button className={`btn-panel ${btnPanelActive === 'specialist' ? 'active-btnPanel' : ''}`} onClick={() => handlePanelInteraction('specialist')}>
            <img src={iconEspecialista} alt='icon especialista'/> Especialista
          </button> */}
        </div>
  
        {!btnPanelActive && (
          <div className='btns-header-options-right'>
            <button className="btn-panel" onClick={handleCopyClick} >
              <img src={iconCopy} alt='icon copiar'/> 
            </button>
            <button className="btn-panel" onClick={handleShare}>
              <img src={iconShare} alt='icon compartir'/> 
            </button>
            {copySuccess && <span className="copy-success-label">¡Copiado!</span>}
          </div>
        )}
  
        {btnPanelActive && (
          <div className='btns-close-min'>
            <div className='btns-header-options-right'>
              <button className="btn-panel" onClick={handleCopyClick} >
                <img src={iconCopy} alt='icon copiar'/> 
              </button>
              <button className="btn-panel" onClick={handleShare}>
                <img src={iconShare} alt='icon compartir'/> 
              </button>
              {copySuccess && <span className="copy-success-label">¡Copiado!</span>}
            </div>
            <button className='btn-micropanel dropdown-btn' onClick={toggleDropdown}>
              <img src={iconDropdown} alt='icon dropdown' className={isDropdownOpen ? '' : 'dropdown-btn-open'}/>
            </button>
            <button className='btn-micropanel close-btn' onClick={closeMicropanel}>
              <img src={iconClose} alt='icon close'/>
            </button>
          </div>
        )}
      </div>
      {showResume && <NormResume content={content} idNorma={idNorma} explication={explication} setExplication={setExplication} />}
      {showQuestion && <NormQuestion content={content} idNorma={idNorma} questionUser={questionUser} setQuestionUser={setQuestionUser} messages={chatMessages} setMessages={setChatMessages} />}
      {showSpecialist && <Specialist />}
    </div>
  </>
);
  
};

export default Micropanel;
