import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './formSpecialist.css';
import logo from '../../../assets/icons/icon-normativa.svg';
import help from '../../../assets/icons/help-circle.svg';
import SearchNormSpecialist from '../../../components/utilities/Specialist/searchNormSpecialist';
import uplFile from '../../../assets/icons/upload.svg';
import iconClose from '../../../assets/icons/close-micropanel.svg';
import iconDropdown from '../../../assets/icons/dropdown-micropanel.svg';
import { DesignCard } from '../../utilities/Specialist/designCard';
import { formSpecialistData, uploadLogo } from '../../../services/formSpecialistData';
import isotipo from '../../../assets/isotipo.svg';


export const FormSpecialist = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const fileInputRef = useRef(null);
  const initialFormData = JSON.parse(localStorage.getItem('formData')) || {
    rut: '',
    name:'',
    razonSocial: '',
    activity: '',
    comuna: '',
    direccion: '',
    region: '',
    email: '',
    webSite: '',
    fileLogo: '',
    fantasyName: '',
    country: '',
    publishPhone: '',
    publishEmail: '',
    phone: '',
    publishLogo: isotipo,
    selectedNorms: [] // Almacenar normativas seleccionadas y descripciones
  };
  const [formData, setFormData] = useState(initialFormData);
  const [selectedNorms, setSelectedNorms] = useState(initialFormData.selectedNorms || []);

  // Guardar formData en localStorage cuando cambia
  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  // Función para actualizar formData
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Función para manejar la norma seleccionada
  const handleSelect = (selectedNorm) => {
    // Verifica si la normativa ya está seleccionada para evitar duplicados
    if (!selectedNorms.some(norm => norm.id === selectedNorm.id)) {
      const updatedNorms = [...selectedNorms, { ...selectedNorm, description: '' }];
      setSelectedNorms(updatedNorms);

      // Actualiza el formData con el nuevo arreglo de normativas seleccionadas
      setFormData((prevData) => ({
        ...prevData,
        selectedNorms: updatedNorms
      }));
    }
  };

  // Función para manejar el cambio de descripción en cada normativa
  const handleDescriptionChange = (normId, description) => {
    const updatedNorms = selectedNorms.map((norm) =>
      norm.id === normId ? { ...norm, description } : norm
    );
    setSelectedNorms(updatedNorms);

    // Actualiza el formData con el nuevo arreglo de normativas y descripciones
    setFormData((prevData) => ({
      ...prevData,
      selectedNorms: updatedNorms
    }));
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const goToStep = (step) => {
    setCurrentStep(step);
  };

  const toggleDropdown = (id) => {
    setSelectedNorms((prevNorms) =>
      prevNorms.map((norm) =>
        norm.id === id ? { ...norm, isOpen: !norm.isOpen } : norm
      )
    );
  };

  const deleteNorm = (id) => {
    const updatedNorms = selectedNorms.filter((norm) => norm.id !== id);
    setSelectedNorms(updatedNorms);

    // Actualiza el formData eliminando la normativa correspondiente
    setFormData((prevData) => ({
      ...prevData,
      selectedNorms: updatedNorms
    }));
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const logoUrl = await uploadLogo(file);
      setFormData((prevData) => ({
        ...prevData,
        publishLogo: logoUrl
      }));
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click(); 
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const specialistData = await formSpecialistData(formData);
      if (specialistData) {
        setFormData(initialFormData); 
        localStorage.removeItem("formData");
        navigate('/');
      }
    } catch (error) {
      console.error('Error de envío del formulario:', error);
    }
  };

  return (
    <div className='specialist-form'>
      <div className="container-form-specialist">
        <div className="left-side-specialist">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="steps-specialist">
            <div className={`step ${currentStep === 1 ? 'active' : ''}`} onClick={() => goToStep(1)}>
              <input type="radio" id="company-details" name="step" checked={currentStep === 1} readOnly />
              <div className="circle"></div>
              <label htmlFor="company-details">
                <span className="step-title">Datos de la empresa</span>
                <span className="step-description">Completa con la información de la empresa</span>
              </label>
            </div>
            <div className={`step ${currentStep === 2 ? 'active' : ''}`} onClick={() => goToStep(2)}>
              <input type="radio" id="legal-representative" name="step" checked={currentStep === 2} readOnly />
              <div className="circle"></div>
              <label htmlFor="legal-representative">
                <span className="step-title">Datos de contacto</span>
                <span className="step-description">Completa con la información de contacto</span>
              </label>
            </div>
            <div className={`step ${currentStep === 3 ? 'active' : ''}`} onClick={() => goToStep(3)}>
              <input type="radio" id="technical-manager" name="step" checked={currentStep === 3} readOnly />
              <div className="circle"></div>
              <label htmlFor="technical-manager">
                <span className="step-title">Selección de Normativas</span>
                <span className="step-description">Elige las Normativas en las cuales quieres aparecer y describe el servicio que ofreces en cada Normativa</span>
              </label>
            </div>
            <div className={`step ${currentStep === 4 ? 'active' : ''}`} onClick={() => goToStep(4)}>
              <input type="radio" id="storage" name="step" checked={currentStep === 4} readOnly />
              <div className="circle"></div>
              <label htmlFor="storage">
                <span className="step-title">Diseño de publicidad</span>
                <span className="step-description">Visualiza en tiempo real y edita cómo se verá tu publicidad</span>
              </label>
            </div>
          </div>
        </div>

        <div className="right-side-specialist">
          {/* PASO 1 */}
          {currentStep === 1 && (
            <div className='specialist-form-body'>
              <div className='form-container-specialist'>
                <h3 className="title">Datos de la empresa</h3>
                <form className="form">
                  <div className="form-group-specialist">
                    <label htmlFor="rut">RUT</label>
                    <input
                      type="text"
                      id="rut"
                      name="rut"
                      placeholder="RUT de la empresa"
                      value={formData.rut}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group-specialist">
                    <label htmlFor="razonSocial">Razón social</label>
                    <input
                      type="text"
                      id="razonSocial"
                      name="razonSocial"
                      placeholder="Ingresar razón social"
                      value={formData.razonSocial}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group-specialist">
                    <label htmlFor="giro">Giro</label>
                    <input
                      type="text"
                      id="activity"
                      name="activity"
                      placeholder="Ingresar giro"
                      value={formData.activity}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group-specialist">
                    <label htmlFor="nameContact">Nombre de Fantasía</label>
                    <input
                      type="text"
                      id="fantasyName"
                      name="fantasyName"
                      placeholder="Nombre de Fantasía"
                      value={formData.fantasyName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group-specialist">
                      <label htmlFor="pais">País</label>
                      <select
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        required
                      >
                        <option value="">País</option>
                        <option value="Chile">Chile</option>
                      </select>
                  </div>
                  <div className="form-group-specialist">
                    <label htmlFor="address">Dirección</label>
                    <input
                      type="text"
                      id="direccion"
                      name="direccion"
                      placeholder="Ingresar dirección"
                      value={formData.direccion}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group-specialist half-width">
                    <div>
                      <label htmlFor="comuna">Comuna</label>
                      <input
                        type="text"
                        id="comuna"
                        name="comuna"
                        placeholder="Ingresar comuna"
                        value={formData.comuna}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="region">Región</label>
                      <select
                        id="region"
                        name="region"
                        value={formData.region}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Región</option>
                        <option value="arica-parinacota">Región de Arica y Parinacota</option>
                        <option value="tarapaca">Región de Tarapacá</option>
                        <option value="antofagasta">Región de Antofagasta</option>
                        <option value="atacama">Región de Atacama</option>
                        <option value="coquimbo">Región de Coquimbo</option>
                        <option value="valparaiso">Región de Valparaíso</option>
                        <option value="ohiggins">Región del Libertador General Bernardo O'Higgins</option>
                        <option value="maule">Región del Maule</option>
                        <option value="nuble">Región de Ñuble</option>
                        <option value="biobio">Región del Biobío</option>
                        <option value="araucania">Región de La Araucanía</option>
                        <option value="los-rios">Región de Los Ríos</option>
                        <option value="los-lagos">Región de Los Lagos</option>
                        <option value="aisen">Región de Aysén del General Carlos Ibáñez del Campo</option>
                        <option value="magallanes">Región de Magallanes y de la Antártica Chilena</option>
                        <option value="metropolitana">Región Metropolitana de Santiago</option>
                      </select>
                    </div>
                  </div>
                  <button type="button" className="submit-button" onClick={nextStep}>Continuar</button>
                </form>
              </div>
            </div>
          )}

          {/* PASO 2 */}
          {currentStep === 2 && (
            <div className='specialist-form-body'>
              <div className='form-container-specialist'>
                <h3 className="title">Datos de contacto</h3>
                <form className="form">
                  <div className="form-group-specialist">
                    <label htmlFor="name">Nombre de contacto</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Nombre de contacto"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group-specialist">
                    <label htmlFor="email">Correo electrónico</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Ingresar correo"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group-specialist">
                    <label htmlFor="phone">Número de teléfono</label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="Ingresar número"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group-specialist">
                    <label htmlFor="webSite">Sitio web</label>
                    <input
                      type="text"
                      id="webSite"
                      name="webSite"
                      placeholder="Ingresar enlace"
                      value={formData.webSite}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <button type="button" className="submit-button" onClick={nextStep}>Continuar</button>
                </form>
              </div>
            </div>
          )}

          {/* PASO 3 */}
          {currentStep === 3 && (
            <div className='specialist-form-body'>
              <div className='form-container-specialist'>
                <h3 className="title">Selección de normativas</h3>
                <form className="form">
                  <SearchNormSpecialist onSelectNorm={handleSelect} />
                  <div className="form-group-specialist">
                    <p className='norms-selecteds'>Normativas seleccionadas <span><img src={help} alt='logo help' /></span></p>
                    <div className='norm-selected-list'>
                      {selectedNorms.map((norm) => (
                        <div key={norm.id} className='specialist-selected-container'>
                          <div className='specialist-norm-title'>
                            <label htmlFor={`norm-${norm.id}`} className='specialist-norm'> 
                              {norm.name}
                            </label>
                            <div className='specialist-btns'>
                              <button type="button" onClick={() => toggleDropdown(norm.id)}>
                                <img
                                  src={iconDropdown}
                                  alt='icon dropdown'
                                  className={norm.isOpen ? '' : 'dropdown-btn-open'}
                                />
                              </button>
                              <button type="button" onClick={() => deleteNorm(norm.id)}>
                                <img src={iconClose} alt='icon close' />
                              </button>
                            </div>
                          </div>
                          {norm.isOpen && (
                            <textarea
                              id={`norm-${norm.id}`}
                              placeholder="Describe el servicio que ofreces para esta Normativa en particular..."
                              value={norm.description || ''} 
                              onChange={(e) => handleDescriptionChange(norm.id, e.target.value)}
                              required
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <button type="button" className="submit-button" onClick={nextStep}>Continuar</button>
                </form>
              </div>
            </div>
          )}

          {/* PASO 4 */}
          {currentStep === 4 && (
            <div className='specialist-form-body'>
              <div className='form-container-specialist'>
                <h3 className="title">Diseño de publicidad</h3>
                <DesignCard 
                  uplFile={formData.publishLogo} 
                  name={formData.fantasyName} 
                  phone={formData.publishPhone} 
                  mail={formData.email} 
                  web={formData.webSite}  
                  description={formData.selectedNorms}
                />
                <form className="form" onSubmit={handleSubmit}>
                  <div className='form-group-specialist'>
                    <label htmlFor='fileLogo'>Logo de la Empresa
                    <div className="icon-help-pass">
                    <img src={help} alt="icon-help" />
                  <span className="tooltip-text">Para una mejor resolución le recomendamos que la medida del logo sea de 40x40.</span>
                  </div>
                    </label>
                    <div className='input-file-card'>
                      <input
                        type='file'
                        id='fileLogo'
                        name='fileLogo'
                        placeholder='Ingrese el logo de la Empresa'
                        ref={fileInputRef} 
                        onChange={handleFileChange} 
                        required
                      />
                      <button type='button' onClick={handleButtonClick} className='btn-file-card'>
                        <img src={uplFile} alt='upload file' />
                      </button>
                    </div>
                  </div>
                  <div className="form-group-specialist">
                    <label htmlFor="phoneBusiness">Número de teléfono</label>
                    <input
                      type="text"
                      id="publishPhone"
                      name="publishPhone"
                      placeholder="Ingresar número"
                      value={formData.publishPhone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group-specialist">
                    <label htmlFor="mailBusiness">Correo electrónico</label>
                    <input
                      type="email"
                      id="publishEmail"
                      name="publishEmail"
                      value={formData.publishEmail}
                      onChange={handleChange}
                      placeholder="Ingresar correo"
                      required
                    />
                  </div>
                  <div className="form-group-specialist">
                    <label htmlFor="webSite">Sitio web</label>
                    <input
                      type="text"
                      id="webSite"
                      name="webSite"
                      placeholder="Ingresar enlace"
                      value={formData.webSite}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <button type="submit" className="submit-button">Confirmar</button>
                </form>
              </div>
            </div>
          )}

          <div className="pagination-container">
            <div className="steps-pagination">
              <div className={`step-pagination${currentStep === 1 ? ' active' : ''}`} onClick={() => goToStep(1)}></div>
              <div className={`step-pagination${currentStep === 2 ? ' active' : ''}`} onClick={() => goToStep(2)}></div>
              <div className={`step-pagination${currentStep === 3 ? ' active' : ''}`} onClick={() => goToStep(3)}></div>
              <div className={`step-pagination${currentStep === 4 ? ' active' : ''}`} onClick={() => goToStep(4)}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSpecialist;
