import axios from 'axios';

const token = process.env.REACT_APP_USER_CREATE_TOKEN;
const apiUrl = process.env.REACT_APP_URL_API;

export const recoveryCode = async (email) => {
  const data = JSON.stringify({
    email,
  });

  const config = {
    method: 'post',
    url: `${apiUrl}user/send/recovery-code`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {

    if (error.response && error.response.status === 401) {
      alert('DATOS INCORRECTOS');
    } else {

        const errorMessage = error.response
        ? error.response.data.message || 'Error desconocido en el servidor'
        : error.message;
      alert(`Error al intentar restablecer la contraseña: ${errorMessage}`);
    }
    throw error;
  }
};

export const VerifyRecoveryCode = async (email,code,newPassword) => {
  const data = JSON.stringify({
    email,
    code,
    newPassword
  });

  const config = {
    method: 'post',
    url: `${apiUrl}user/verify/recovery-code`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {

    if (response.data.data.message=== "User with email 'undefined' not found") {
      alert('USUARIO NO ENCONTRADO');
    } if (response.data.data.message=== "Invalid recovery code 'null") {
      alert('CODIGO INCORRECTO');
    } else {

        const errorMessage = error.response
        ? error.response.data.message || 'Error desconocido en el servidor'
        : error.message;
      alert(`Error al intentar restablecer la contraseña: ${errorMessage}`);
    }
    throw error;
  }
};
