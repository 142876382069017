import React, { useState } from 'react';
import './restorePass.css';
import { recoveryCode, VerifyRecoveryCode } from '../../../services/restorePassCode';
import eyeOff from '../../../assets/icons/eye-off.svg';
import eyeOn from '../../../assets/icons/eye-onn.svg';

const RestorePass = () => {
  const [email, setEmail] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); 

  const handleRecovery = async (e) => {
    e.preventDefault();
    try {
      const recoveryData = await recoveryCode(email);
      if (recoveryData) {
        setShowCodeInput(true);
        setVerificationCode('');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCodeVerification = (e) => {
    e.preventDefault();
    localStorage.setItem('verificationCode', verificationCode);
    setShowPasswordInput(true); 
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage('Las contraseñas no coinciden');
      return;
    }
    try {
      const verificationResponse = await VerifyRecoveryCode(
        email,
        localStorage.getItem('verificationCode'),
        newPassword,
        verificationCode
      );
      if (verificationResponse.data === "Password reset successfully") {
        setErrorMessage('Contraseña restablecida correctamente');
        localStorage.removeItem('verificationCode');
      } else if (verificationResponse.data.message === `Invalid recovery code '${verificationCode}'`) {
        setErrorMessage('El codigo de verifcación no es correcto');
      } else if (verificationResponse.data.message === `User with email '${email}' not found`) {
        setErrorMessage('El usuario no se encuentra registrado');
      } else {
        setErrorMessage(''); 
      }
      
      console.log(verificationResponse.data.message);
    } catch (error) {
      setErrorMessage('Error al verificar el código de recuperación');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="restore">
      {!showCodeInput && !showPasswordInput ? (
        // Primer paso: Solicitar correo electrónico
        <div className="restore-body">
          <div className="restore-title">
            <h3>Restablecer contraseña</h3>
            <p>Indícanos tu correo electrónico para enviarte tu código de recuperación de contraseña</p>
          </div>
          <div className="restore-verification">
            <span>Correo electrónico</span>
            <input
              type="email"
              id="email"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="restore-submit">
            <button onClick={handleRecovery}>Continuar</button>
          </div>
        </div>
      ) : showCodeInput && !showPasswordInput ? (
        // Segundo paso: Ingresar código de verificación
        <div className="restore-body">
          <div className="restore-title">
            <h3>Restablecer contraseña</h3>
            <p>Hemos enviado un correo electrónico a {email} con tu código de recuperación de contraseña</p>
          </div>
          <div className="restore-verification">
            <span>Código de recuperación</span>
            <input
              type="text"
              id="codeVerification"
              placeholder="Ingresar código de recuperación"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
          </div>
          <div className="restore-submit">
            <button onClick={handleCodeVerification}>Continuar</button>
            <div className="restore-support">
              <span>¿No recibes tu código?</span>
              <a href="/contacto">Contacta a soporte</a>
            </div>
          </div>
        </div>
      ) : (
        // Tercer paso: Ingresar nueva contraseña
        <div className="restore-body">
          <div className="restore-title">
            <h3>Restablecer contraseña</h3>
          </div>
          <div className="restore-verification">
            <span>Nueva contraseña</span>
            <input
              type={passwordVisible ? 'text' : 'password'}
              id="newPassword"
              placeholder="Ingresar nueva contraseña"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <img
              src={passwordVisible ? eyeOn : eyeOff}
              alt={passwordVisible ? 'Mostrar contraseña' : 'Ocultar contraseña'}
              onClick={togglePasswordVisibility}
              className="toggle-password-visibility"
            />
          </div>
          <div className="restore-verification">
            <span>Confirmar contraseña</span>
            <input
              type={passwordVisible ? 'text' : 'password'}
              id="confirmPassword"
              placeholder="Confirmar nueva contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <img
              src={passwordVisible ? eyeOn : eyeOff}
              alt={passwordVisible ? 'Mostrar contraseña' : 'Ocultar contraseña'}
              onClick={togglePasswordVisibility}
              className="toggle-password-visibility"
            />
          </div>
          {errorMessage && <p className="error-message-restore">{errorMessage}</p>}
          <div className="restore-submit">
            <button onClick={handleVerifyCode}>Restablecer contraseña</button>
            <div className="restore-support">
              <span>¿No recibes tu código?</span>
              <a href="/contacto">Contacta a soporte</a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RestorePass;
