import React, { useState, useRef, useEffect } from 'react';
import { lawSearch, similaritySearch } from '../../../services/lawSearch';
import iconDate from '../../../assets/icons/calendar-days.svg'
import iconoSearch from '../../../assets/icons/search.svg';
import iconoDelete from '../../../assets/icons/delete-input.svg';
import Loading from '../../utilities/Loading/loading';
import prevPage from '../../../assets/icons/arrow-left.svg';
import nextPage from '../../../assets/icons/arrow-right.svg';
import notResults from '../../../assets/icons/not-results.svg'
import CustomDropdown from '../../utilities/DropDown/dropdown';
import './searchNormSpecialist.css';

const SearchNormSpecialist = ( { onSelectNorm}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [confirmedSearchTerm, setConfirmedSearchTerm] = useState('');
  const [results, setResults] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const lastSearchIdRef = useRef(0);

  useEffect(() => {
    if (searchTerm) {
      setResults(null);
      setError(null);
    } else {
      setSuggestions([]);
    }
  }, [searchTerm]);

  function stripHtmlTags(str) {
    return str.replace(/<[^>]*>/g, '');
  }

  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  const toCapitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const truncateText = (text, maxLength) => {
    const cleanText = decodeHtml(stripHtmlTags(text));
    if (cleanText.length > maxLength) {
      return toCapitalize(cleanText.slice(0, maxLength)) + '...';
    }
    return toCapitalize(cleanText);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchTerm.trim()) {
      setError('El término de búsqueda no puede estar vacío');

      return;
    }

    setConfirmedSearchTerm(searchTerm);
    setError(null);
    setIsLoading(true); 
    setResults(null);
    const searchId = ++lastSearchIdRef.current;

    try {
      const data = await lawSearch(searchTerm, currentPage, pageSize);
      if (searchId !== lastSearchIdRef.current) {
        return;
      }

      if (!data.data.content || data.data.content.length === 0) {
        setError('No se encontraron resultados.');
        setResults(null);
        setSuggestions([]); 
      } else {
        setResults(data.data);
        setError(null);
        setSuggestions([]); 
      }
    } catch (err) {
      if (searchId !== lastSearchIdRef.current) {
        return;
      }
      setError('No se encontraron resultados');
    } finally {
      setIsLoading(false); 
    }
  };

  const handlePageChange = async (newPage) => {
    if (newPage < 1 || (results && newPage > Math.ceil(results.total / pageSize))) {
      return;
    }
    setCurrentPage(newPage);
    const searchId = ++lastSearchIdRef.current;

    try {
      const data = await lawSearch(searchTerm, newPage, pageSize);
      if (searchId !== lastSearchIdRef.current) {
        return;
      }
      setResults(data.data);
    } catch (err) {
      if (searchId !== lastSearchIdRef.current) {
        return;
      }
      setError('No se encontraron resultados');
    }
  };

  const handlePageSizeChange = async (size) => {
    setPageSize(size);
    setCurrentPage(1);
    const searchId = ++lastSearchIdRef.current;

    try {
      const data = await lawSearch(searchTerm, 1, size);
      if (searchId !== lastSearchIdRef.current) {
        return;
      }
      setResults(data.data);
    } catch (err) {
      if (searchId !== lastSearchIdRef.current) {
        return;
      }
      setError('No se encontraron resultados');
    }
  };

  const handleDelete = () => {
    lastSearchIdRef.current++;
    setSearchTerm('');
    setResults(null);
    setSuggestions([]);
    setError(null);
    setIsLoading(false);
  };

  const handleSelect = (norm) => {
    const normaId = norm.idNorm; 
    const selectedNorm = {
      id: normaId,
      name: norm.normName || '', 
      description: '', 
      isOpen: true, 
    };
    onSelectNorm(selectedNorm); 
    setResults(null); 
    setSearchTerm('');
  };
  
  
  const renderResultItem = (item, index) => (
    <li key={index}  className="lawResults-specialist"  onClick={() => handleSelect(item)}>
      <p  className="lawDate-specialist"><img src={iconDate} alt="iconDate" />{toCapitalize(item.publicDate)}</p>
      <div className="">
        <p className="lawTitle-specialist">
          {item.norName ? `${toCapitalize(item.normName)}` : toCapitalize(item.normName)}
        </p>
        <p className="lawResumen-specialist">{toCapitalize(item.title)}</p>
      </div>
      <div className="lawOrganism-specialist">
        {item.organism ? (
          Array.isArray(item.organism) ? (
            item.organism.map((org, index) => (<p key={index}>{toCapitalize(org)}</p>))
          ) : (
            <p>{toCapitalize(item.organism)}</p>
          )
        ) : (
          <p>No hay información del organismo.</p>
        )}
      </div>
    </li>
  );

  return (
    <div className="searchNorm-specialist">
      <div className="searchContainer-specialist">
    <div className="inputSearch-specialist">
      <div className="inputContainer-specialist">
        <img src={iconoSearch} alt="icono-search" className="input-icon" />
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSearch(e); 
            }
          }}          placeholder="Busca la normativa que necesitas"
          className="searchInput-specialist"
        />
        {isLoading && (
          <div className="loadingMessage">
            <Loading />
            Buscando...
          </div>
        )}
        {searchTerm && (
          <img
            src={iconoDelete}
            alt="icono-delete"
            className="deleteIcon"
            onClick={handleDelete}
          />
        )}
        <button onClick={handleSearch} className="btnInputSearch-specialist">
          Buscar
        </button>
      </div>
    </div>

    {suggestions.length > 0 && (
      <div className="suggestionsContainer-specialist">
        <ul>
          <div className="headerSearch-specialist">
            <p>Sugerencias basadas en "<span>{searchTerm}</span>"</p>
          </div>
          {suggestions.map(renderSuggestionItem)}
        </ul>
      </div>
    )}
  </div>

  {error && (
    <div className="errorMessage">
      <div className="errorBody">
        <div className="titleError">
          <img src={notResults} alt="not results" />
          <p>{error}</p>
        </div>
        <div className="subtitleError">
          <p>
            Lo sentimos, pero no hemos encontrado normativas que coincidan
            con tu búsqueda. Por favor prueba nuevamente ajustando tus
            términos de búsqueda
          </p>
        </div>
      </div>
    </div>
  )}

  {results && !isLoading && (
    <div className="containerResults-specialist">
      <ul>
      <div className="headerSearch-specialist">
          <p>Resultados para la búsqueda de <span>"{confirmedSearchTerm}".</span>  Se han encontrado <span> {results.total} resultados</span></p>
          <div className="paginationControls-specialist">
            <CustomDropdown pageSize={pageSize} handlePageSizeChange={handlePageSizeChange} />
            <div className="controllers-specialist">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`prevPageBtn ${currentPage === Math.ceil(results.total / pageSize) ? "disabled" : ''}`}
              >
                <img src={prevPage} alt="prev page" />
              </button>
              <span>{currentPage} - {Math.ceil(results.total / pageSize)}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === Math.ceil(results.total / pageSize)}
                className={`nextPageBtn ${currentPage === Math.ceil(results.total / pageSize) ? "disabled" : ''}`}
              >
                <img src={nextPage} alt="next page" />
              </button>
            </div>
          </div>
        </div>
        {results.content.map(renderResultItem)}
      </ul>
    </div>
  )}
</div>

  );
};

export default SearchNormSpecialist;



