import './normResume.css';
import { useEffect, useState } from 'react';
import { ProgressBarLoading } from '../ProgressBar/progressBar';
import { iaResumeNorm } from '../../../services/iaResumeNorm'; 

export const NormResume = ({ content, idNorma, uniqueId, explication, setExplication }) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (explication) {
      setIsLoading(false);
      return;
    }

    const fetchExplanation = async () => {
      try {
        const response = await iaResumeNorm({ content, idNorma, uniqueId });
        setExplication(response.data.content); 
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchExplanation();
  }, [content, idNorma, uniqueId, explication, setExplication]);

  return (
    <div className={`explication-container ${isLoading ? 'centered' : 'flex-start'}`}>
      {error && <p>Error: {error}</p>}
      {isLoading ? (
        <div className='progress-container'>
          <ProgressBarLoading 
            title="Generando explicación IA..." 
            description="Leyendo el artículo de la normativa" 
          />
        </div>
      ) : (
        <div className='explication-content'>
          <p>{explication}</p>

          <span>Explicación generada con IA Normativa</span>
        </div>
      )}
    </div>
  );
};