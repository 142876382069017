import React from 'react';
import logo from '../../../assets/icons/icon-normativa.svg'
import './Footer.css'

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer-container'>
                <div className='logo'>
                    <img src={logo} alt="Logo" />
                    <span>Normativa Legal facilita el acceso y comprensión de las leyes chilenas usando IA. Utiliza nuestro buscador, interactúa con artículos y conecta con especialistas según tus requerimientos. 
                        Mantente al día con las últimas normativas y proyectos de ley.</span>
                </div>
                <div className='footer-enlaces'>
                    <div className='enlaces-uno'>
                        <p>Enlaces</p>
                      <a href="" target="_blank" rel="noopener noreferrer">Inicio</a>
                    </div>
                    <div className='enlaces-dos'>
                    <p>Soporte</p>
                      <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Ayuda</a>
                        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Contacto</a>
                    </div>
                  
                </div>
            </div>
            <div className='footer-footer'>
                <div className='copyright'>
                  <span>© 2024 Normativa Legal. Derechos reservados</span>
                </div>
                <div className='politicas'>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Política de privacidad</a>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Términos de servicio</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
