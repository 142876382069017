import React, { useState, useEffect } from 'react';
import './dataProject.css';
import iconClose from '../../assets/icons/close-menuanchor.svg';
import iconBack from '../../assets/icons/arrow-left.svg';
import { TramitacionProject } from './tramitacionProject';
import { ChatProject } from '../../components/lastProject/chatProject';
import { CloseIaProject } from '../utilities/Modals/closeIaProject';
import { Votacion } from './votacion';
import { ProgressBarLoading } from '../../components/utilities/ProgressBar/progressBar';
import { iaChatDocument } from '../../services/iaChatProject';
import { deleteChat } from '../../services/deleteChat';


export const DataProject = ({ project,allProjectsData, onClose,assistantName}) => {
    const [showTramitacion, setShowTramitacion] = useState(true);
    const [showIa, setShowIa] = useState(false);
    const [showVotacion, setShowVotacion] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [isIaProjectCreated, setIsIaProjectCreated] = useState(false);
    const [selectedVotacion, setSelectedVotacion] = useState(null);
    const [btnProjectActive, setBtnProjectActive] = useState('Tramitación');
    const [showCloseModal, setShowCloseModal] = useState(false);
    const [progressTimeout, setProgressTimeout] = useState(null);
    const [namespaceCounter, setNamespaceCounter] = useState(2);
    const [isTaskCompleted, setIsTaskCompleted] = useState(false);

    // useEffect(() => {
    //     if (project && Array.isArray(project.detail)) {
    //         project.forEach((project, index) => {
    //             if (project.VOTACION) {
                   
    //             } else {
                    
    //             }
    //         });
    //     }
    // }, [project]);

    const tramitacionClick = () => {
        clearTimeout(progressTimeout);
        setShowTramitacion(true);
        setShowIa(false);
        setShowVotacion(false);
        setShowProgressBar(false);
        setBtnProjectActive('Tramitación');
    };

    const votacionClick = (votacion) => {
        clearTimeout(progressTimeout);
        setSelectedVotacion(votacion);
        setShowVotacion(true);
        setShowTramitacion(false);
        setShowIa(false);
        setShowProgressBar(false);
    };

    const handleBackClick = () => {
        clearTimeout(progressTimeout);
        setShowVotacion(false);
        setShowTramitacion(true);
        setSelectedVotacion(null);
    };

    const handleShowCloseModal = () => {
        clearTimeout(progressTimeout);
        setShowCloseModal(true);
    };

    const handleCloseCloseModal = () => {
        setShowCloseModal(false);
    };

    const handleFinalClose = async () => {
        onClose();
    };

    const handleProgressBarComplete = () => {
        setShowProgressBar(false);
        setShowIa(true);
        setIsIaProjectCreated(true);
        setIsTaskCompleted(true);
    };

    const iaClick = async () => {
        if (isTaskCompleted) {
            setShowIa(true);
            setBtnProjectActive('IA Proyectos');
            setShowTramitacion(false);
            return;
        }

        setShowProgressBar(true);
        setBtnProjectActive('IA Proyectos');
        setShowTramitacion(false);

        try {
            const response = await iaChatDocument({ allProjectsData });
            setProgressTimeout(setTimeout(() => {
                handleProgressBarComplete();
            }, 10000)); 
        } catch (error) {
            console.error('Error creating IA project:', error);
            setShowProgressBar(false);
        }
    };

    return (
        <>
            <div className="modal-overlay">
                <div className='modal-container'>
                    <div className='modal-project'>
                        <div className='table-project'>
                            <div className='header-project'>
                                <div className='menu-projects'>
                                    <button
                                        className={`btn-project ${btnProjectActive === 'Tramitación' ? 'active-btn-project' : ''}`}
                                        onClick={tramitacionClick}
                                    >
                                        Tramitación
                                    </button>
                                    {/* <button
                                        className={`btn-project ${btnProjectActive === 'IA Proyectos' ? 'active-btn-project' : ''}`}
                                        onClick={iaClick}
                                    >
                                        IA Proyectos
                                    </button> */}
                                </div>
                                <div className='btns-header'>
                                    {showVotacion && (
                                        <button className='back-modal' onClick={handleBackClick}>
                                            <img src={iconBack} alt='back-modal' />
                                            <span>Volver</span>
                                        </button>
                                    )}
                                    <button onClick={handleShowCloseModal} className='close-project'>
                                        <img src={iconClose} alt='close-modal' />
                                    </button>
                                </div>
                            </div>

                            {showTramitacion && project  && Array.isArray(project) && (
                                <TramitacionProject tramitacion={project} onVotacionClick={votacionClick} />
                            )}
                            {showProgressBar && (
                                <ProgressBarLoading onComplete={handleProgressBarComplete} title="Creando IA Proyectos.."
                                    description="Cargando los archivos del proyecto de ley a la IA" />
                            )}
                            {showIa && (
                                <ChatProject projects={allProjectsData}/>
                            )}
                            {showVotacion && selectedVotacion && (
                                <Votacion votacion={selectedVotacion} />)}
                        </div>
                    </div>
                </div>

                {showCloseModal && (
                    <div className="modal-overlay">
                        <CloseIaProject onClose={handleCloseCloseModal} onFinalClose={handleFinalClose} project={project} />
                    </div>
                )}
            </div>
        </>
    );
};

export default DataProject;
