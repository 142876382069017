// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/icons/arrow-left.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button#dropdown-basic-button {   
    display: flex;
    cursor: pointer;
    padding: 8px 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid  #E7E7E7;
    background:  #F6F6F6;
    color:rgba(69, 69, 69, 1);
}
button#dropdown-basic-button:hover{
    border: 1px solid #FCCCCC;
}
.dropdown-toggle::after {
    vertical-align: 0 !important;
    content: "" !important;
     border-top: none !important; 
     border-right: none !important;
     border-bottom: none !important;
     border-left: none !important;
}
button#dropdown-basic-button::after {
    content: ""!important;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 12px;
    height: 12px;
    transform: rotate(268deg) !important;
    margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/utilities/DropDown/dropdown.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;IACR,kBAAkB;IAClB,0BAA0B;IAC1B,oBAAoB;IACpB,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,4BAA4B;IAC5B,sBAAsB;KACrB,2BAA2B;KAC3B,6BAA6B;KAC7B,8BAA8B;KAC9B,4BAA4B;AACjC;AACA;IACI,qBAAqB;IACrB,oEAAsE;IACtE,qBAAqB;IACrB,4BAA4B;IAC5B,2BAA2B;IAC3B,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,gBAAgB;AACpB","sourcesContent":["button#dropdown-basic-button {   \n    display: flex;\n    cursor: pointer;\n    padding: 8px 12px;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    gap: 4px;\n    border-radius: 6px;\n    border: 1px solid  #E7E7E7;\n    background:  #F6F6F6;\n    color:rgba(69, 69, 69, 1);\n}\nbutton#dropdown-basic-button:hover{\n    border: 1px solid #FCCCCC;\n}\n.dropdown-toggle::after {\n    vertical-align: 0 !important;\n    content: \"\" !important;\n     border-top: none !important; \n     border-right: none !important;\n     border-bottom: none !important;\n     border-left: none !important;\n}\nbutton#dropdown-basic-button::after {\n    content: \"\"!important;\n    background-image: url(../../../assets/icons/arrow-left.svg) !important;\n    background-size: auto;\n    background-repeat: no-repeat;\n    background-position: center;\n    display: inline-block;\n    width: 12px;\n    height: 12px;\n    transform: rotate(268deg) !important;\n    margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
