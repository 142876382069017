// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-border.text-dark {
    color:rgba(232, 69, 69, 1) !important;
    width: 16px;
    height: 16px;
}
.spinner-border{
    --bs-spinner-border-width: 0.15em;
}`, "",{"version":3,"sources":["webpack://./src/components/utilities/Loading/loading.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,WAAW;IACX,YAAY;AAChB;AACA;IACI,iCAAiC;AACrC","sourcesContent":[".spinner-border.text-dark {\n    color:rgba(232, 69, 69, 1) !important;\n    width: 16px;\n    height: 16px;\n}\n.spinner-border{\n    --bs-spinner-border-width: 0.15em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
