import axios from 'axios';
const urlApi = process.env.REACT_APP_URL_API;
const token = process.env.REACT_APP_USER_CREATE_TOKEN;

export const iaResumeNorm = async ({ content, idNorma, uniqueId }) => {
  const config = {
    method: 'POST',
    url: `${urlApi}chat`,
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json',
    },
    data: {
      uniqueId: uniqueId,
      userMessage: `CONTEXTO ${content} INSTRUCCION: Explicalo de una forma mas amigable. NO digas claro ni nada que no tenga que ver con el resumen, directamente pare con el resumen`,
      instructions: "Eres un experto explicando cosas de forma amigable.",
      panelInput: "explication",
      idNorm: idNorma,
    },
  };

  try {
    const response = await axios(config);
    if (response.data) {
      return response.data;
    } else {
      throw new Error('La respuesta no contiene el campo taskId esperado');
    }
  } catch (error) {
    console.error('Hubo un problema al procesar la solicitud:', error.response ? error.response.data : error.message);
    throw error;
  }
};
export const getExplanation = async (taskId) => {
  const config = {
    method: 'GET',
    url: `${urlApi}tasks/${taskId}/asistente`,
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(config);
    if (response.data) { 
      console.log(response.data)
      return response.data;
    } else {
      throw new Error('La respuesta no contiene la explicación esperada');
    }
  } catch (error) {
    console.error('Hubo un problema al procesar la solicitud:', error.response ? error.response.data : error.message);
    throw error;
  }
};


  
// export const iaTaskResumeNorm = async ({ content, idNorma }) => {
//   console.log(idNorma)
//   const config = {
//     method: 'POST',
//     url: `${urlApi}chat`,
//     headers: {
//       'Authorization': token,
//       'Content-Type': 'application/json',
//     },
//     data: {
//       "uniqueId": "",
//       "userMessage": `CONTEXTO ${content} INSTRUCCION: Explicalo de una forma mas amigable.NO digas claro ni nada que no tenga que ver con el resumen, directamente pare con el resumen`,
//       "instructions": "Eres un experto explicando cosas de forma amigable.",
//       "panelInput": "explication",
//       "idNorm": idNorma,
//     },
//   };
  
//   try {
//     const response = await axios(config);
//     if (response.data) {
//       return response.data.taskId; 
//     } else {
//       throw new Error('La respuesta no contiene el campo taskId esperado');
//     }
//   } catch (error) {
//     console.error('Hubo un problema al procesar la solicitud:', error.response ? error.response.data : error.message);
//     throw error;
//   }
// };