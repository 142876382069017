import React from 'react';
import iconCloseMenu from '../../../assets/icons/close-menuanchor.svg';
import './anchorMenu.css';

const AnchorMenu = ({ articleTitles, activeTitleId, activeArticleId, onClose }) => {
  const handleLinkClick = (event, index) => {
    event.preventDefault();
    const targetElement = document.getElementById(index);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const renderArticles = (parentId) => {
    return articleTitles
      .filter((article) => article.parentId === parentId)
      .map((article) => (
        <li key={article.id} className={`article-item ${activeArticleId === article.id ? 'active' : ''}`}>
          <a href={`#${article.id}`} onClick={(e) => handleLinkClick(e, article.id)}>
            {article.title.split(':')[0]}
          </a>
        </li>
      ));
  };  

  return (
    <div className="anchor-menu">
      <ul>
        <div className="header-anchorMenu">
          <p style={{color: '#6173a3'}}>ÍNDICE</p>
          <button className="close-anchorMenu" onClick={onClose}>
            <img src={iconCloseMenu} alt="close-menu" />
          </button>
        </div>
        <li className={`title-item ${activeArticleId === 'encabezado' ? 'active' : ''}`}>
          <a href="#norma-inicio" onClick={(e) => handleLinkClick(e, 'encabezado')}>
            Encabezado
          </a>
        </li>
        {articleTitles.map((article) => (
          <React.Fragment key={article.id}>
            {article.type === 'titulo' && (
              <>
                <li className={`title-item ${activeTitleId === article.id ? 'active' : ''}`}>
                  <a href={`#${article.id}`} onClick={(e) => handleLinkClick(e, article.id)}>
                    {article.title}
                  </a>
                </li>
                {(activeTitleId === article.id || articleTitles.some((art) => art.parentId === article.id && activeArticleId === art.id)) &&
                  renderArticles(article.id)}
              </>
            )}
            {article.type === 'promulgacion' && (
              <li className={`title-item ${activeArticleId === 'promulgacion' ? 'active' : ''}`}>
                <a href={`#${article.id}`} onClick={(e) => handleLinkClick(e, article.id)}>
                  Promulgación
                </a>
              </li>
            )}
            {article.type === 'anexo' && (
              <li className={`title-item ${activeArticleId === 'anexo' ? 'active' : ''}`}>
                <a href={`#${article.id}`} onClick={(e) => handleLinkClick(e, article.id)}>
                  Anexo
                </a>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default AnchorMenu;

