import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import AnchorMenu from '../../components/utilities/AnchorMenu/anchorMenu';
import Micropanel from '../../components/utilities/MicroPanel/microPanel';
import Loading from '../../components/utilities/Loading/loading';
import { Helmet } from 'react-helmet';
import { normContent,encabezadoNorm } from '../../services/normContent';
import { parseXmlToReact, renderTitleAndOrganisms, parseArticleTitles, extractTitleData, extractMetaDescription } from '../../services/xmlParser';
import iconIndice from '../../assets/icons/indice-btn.svg';
import ScrollToTopButton from '../../components/utilities/ScrollTopButton/scrollTopButton';
import './normContentPage.css';
import TutorialMp from '../../components/utilities/Tutorial/tutorialMp';
import ResultsModal from '../../components/ResultsModal/ResultsModal';
import iconPrint from '../../assets/icons/printer.svg'
import iconDowland from '../../assets/icons/download.svg'
import iconShare from '../../assets/icons/share-2.svg'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

const NormContentPage = () => {
  const { idNorma } = useParams();
  const [content, setContent] = useState(null);
  const [encabezadoContent,setEncabezadoContent] =useState(null);
  const [error, setError] = useState(null);
  const [articleTitles, setArticleTitles] = useState([]);
  const [activeTitleId, setActiveTitleId] = useState(null);
  const [activeArticleId, setActiveArticleId] = useState(null);
  const [hoveredArticle, setHoveredArticle] = useState(null);
  const [isMicropanelHovered, setIsMicropanelHovered] = useState(false);
  const [metaDescription, setMetaDescription] = useState('');
  const [isAnchorMenuVisible, setIsAnchorMenuVisible] = useState(false);
  const [fixedMicropanelIndex, setFixedMicropanelIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [isResumenExpanded, setIsResumenExpanded] = useState(false);

  const titleRefs = useRef([]);
  const articleRefs = useRef([]);

  const [titleData, setTitleData] = useState({
    tipo: '',
    organismo: '',
    numero: '',
    tituloNorma: '',
    fechaPublicacion: '',
    organismo: '',
    encabezado: '',
    idNorma: '',
    fechaPromulgacion: '',
    fechaVersion: '',
    materiaNorma: ''
  });

  
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const data = await normContent(idNorma);
        const encabezadoData= await encabezadoNorm(idNorma);
        console.log(encabezadoData.data)
        setEncabezadoContent(encabezadoData)
        setContent(data);
        setArticleTitles(parseArticleTitles(data));
  
        const titleData = extractTitleData(data);
        setTitleData(titleData);
  
        const description = extractMetaDescription(data);
        setMetaDescription(description);
  
      } catch (error) {
        setError(error.message);
        setTimeout(() => {
          window.open(`https://www.bcn.cl/leychile/navegar?idNorma=${idNorma}`, '_blank');
        }, 3000);
      }
    };
  
    fetchContent();
  }, [idNorma]);


  
  useEffect(() => {
    // Establecer visibilidad del menú de anclaje basado en el tamaño de la pantalla
    if (content) {
      setIsAnchorMenuVisible(window.innerWidth > 768); // Visible en pantallas de escritorio
    }
  }, [content]);


  useEffect(() => {
    if (window.location.hash) {
      const element = document.getElementById(window.location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [content]);
  // Construir el título completo con abreviaciones
  const completeTitle = content ? `NL Chile ${titleData.tipo} ${titleData.numero} (${formatDate(titleData.fechaPublicacion)}) ${titleData.organismo}` : "";

  useEffect(() => {
    if (content) {
      const observer = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      });

      const observerForArticles = new IntersectionObserver(handleArticleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      });

      titleRefs.current.forEach((ref) => {
        if (ref) {
          observer.observe(ref);
        }
      });

      articleRefs.current.forEach((ref) => {
        if (ref) {
          observerForArticles.observe(ref);
        }
      });

      return () => {
        observer.disconnect();
        observerForArticles.disconnect();
      };
    }
  }, [content]);

  // Manejar la intersección de títulos
  const handleIntersection = useCallback((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const id = entry.target.id;
        setActiveTitleId(id);
      }
    });
  }, []);

  const decodeHtmlEntities = (text) => {
    const element = document.createElement('div');
    if (text) {
      element.innerHTML = text;
      return element.textContent;
    }
    return '';
  };
  
  // Manejar la intersección de artículos
  const handleArticleIntersection = useCallback((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const id = entry.target.id;
        setActiveArticleId(id);

        const parentTitleId = articleTitles.find((article) => article.id === id)?.parentId;
        if (parentTitleId) {
          setActiveTitleId(parentTitleId);
        }
      }
    });
  }, [articleTitles]);

  const toggleAnchorMenuVisibility = () => {
    setIsAnchorMenuVisible(!isAnchorMenuVisible);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleResume =() =>{
    setIsResumenExpanded(!isResumenExpanded);
  }
  function formatDate(dateString) {
    const [year, month, day] = dateString.split('-');
      return `${day}-${month}-${year}`;
  }
  

  const handlePrint = () =>{
    window.print();
    setIsAnchorMenuVisible(false)
  }
  const handleShare = () => {
    const urlToShare = (`https://normativa.legal/chile/${idNorma}`);
    const textToShare = "Normativa Legal "; 
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(textToShare)}${encodeURIComponent(urlToShare)}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleDownload = async () => {
    const pdfDoc = await PDFDocument.create();
    
    const pageWidth = 595.28; 
    const pageHeight = 841.89; // Tamaño A4 en puntos
    const margin = 40;
    const fontSize = 12;
    const lineHeight = fontSize + 4;
    const textWidth = pageWidth - margin * 2;
  
    const contentElement = document.querySelector('.norma-container');
    let textContent = contentElement.innerText;
    const cleanText = (text) => {
      return text.replace(/[^\x20-\x7E]/g, '-');
    };
    
    textContent = cleanText(textContent);
  
    const lines = textContent.split('\n').flatMap(line => {
      const words = line.split(' ');
      let currentLine = '';
      const result = [];
  
      for (const word of words) {
        if ((currentLine.length + word.length) > (textWidth / fontSize)) {
          result.push(currentLine.trim());
          currentLine = word + ' ';
        } else {
          currentLine += word + ' ';
        }
      }
  
      if (currentLine.length > 0) {
        result.push(currentLine.trim());
      }
  
      return result;
    });
  
    let yOffset = pageHeight - margin;
  
    let page = pdfDoc.addPage([pageWidth, pageHeight]);
  
    for (const line of lines) {
      if (yOffset - lineHeight < margin) {
        page = pdfDoc.addPage([pageWidth, pageHeight]);
        yOffset = pageHeight - margin;
      }
  
      page.drawText(line, {
        x: margin,
        y: yOffset,
        size: fontSize,
        maxWidth: textWidth,
        lineHeight: lineHeight,
        color: rgb(0, 0, 0),
      });
  
      yOffset -= lineHeight;
    }
  
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'norma.pdf';
    link.click();
  };
  

  return (
    <div className="norma">
      <Helmet>
        <title>
          {completeTitle}
        </title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div className="header-norma">
        <div className="anchor-btns">
          <button
            className={`btn-anchorMenu ${isAnchorMenuVisible ? 'hidden' : ''}`}
            onClick={toggleAnchorMenuVisibility}
          >
            <img src={iconIndice} alt="icon indice" />Índice
          </button>
          <button
            className="btn-anchorMenu"
            onClick={openModal}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: '8px' }} 
            >
              <path
                d="M10.6665 10.6667L12.6665 5.33337L14.6665 10.6667C14.0865 11.1 13.3865 11.3334 12.6665 11.3334C11.9465 11.3334 11.2465 11.1 10.6665 10.6667Z"
                stroke="#454545"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.3335 10.6667L3.3335 5.33337L5.3335 10.6667C4.7535 11.1 4.0535 11.3334 3.3335 11.3334C2.6135 11.3334 1.9135 11.1 1.3335 10.6667Z"
                stroke="#454545"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.6665 14H11.3332"
                stroke="#454545"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 2V14"
                stroke="#454545"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 4.66671H3.33333C4.66667 4.66671 6.66667 4.00004 8 3.33337C9.33333 4.00004 11.3333 4.66671 12.6667 4.66671H14"
                stroke="#454545"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Jurisprudencia
          </button>
        </div>
      </div>

      <div className="body-content">
        <TutorialMp />
        {error && <div>
         <div className=''>
          <h2>Contenido no disponible</h2>
          <p>Redirigiendo a Bcn ...  
          </p>
          
          </div>
         </div>}
         {!error && isAnchorMenuVisible && (
          <AnchorMenu
            articleTitles={articleTitles}
            activeTitleId={activeTitleId}
            activeArticleId={activeArticleId}
            onClose={toggleAnchorMenuVisibility}
          />
        )}
         {!error && content ? (

          <div className="norma-container">
            <div id="norma-inicio" className="texto-articulo">{renderTitleAndOrganisms(content)}</div>
            <div className='norma-data'>
            <div className='norm-data-container'>
              <p>Promulgación: <span>{encabezadoContent && formatDate(encabezadoContent.data.promulgacion)}</span></p>
              <p>Publicación: <span>{encabezadoContent && formatDate(encabezadoContent.data.publicacion)}</span></p>
              <p>Versión: <span>{encabezadoContent && (encabezadoContent.data.tipoVersion)} - {encabezadoContent && formatDate(encabezadoContent.data.fechaVersion)}</span></p>
              {encabezadoContent && encabezadoContent.data.materia && encabezadoContent.data.materia.length > 0 && (
              <div className='materias-norm'>
                <p>Materias: <span>{encabezadoContent.data.materia.join(', ')}</span></p>
              </div>
            )}
                {encabezadoContent && encabezadoContent.data.resumen && (
                  <div>
                    <p>Resumen:
                      <span>
                      {isResumenExpanded ? decodeHtmlEntities(encabezadoContent.data.resumen) : `${decodeHtmlEntities(encabezadoContent.data.resumen.substring(0, 300))}...`}
                        <button className='btn-resume' onClick={handleResume}>
                          {isResumenExpanded ? 'Ver menos' : 'Ver más'}
                        </button>
                      </span>
                    </p>
                  </div>
                )}
            </div>
            <div className='norm-data-icons'>
            <button onClick={handlePrint}><img src={iconPrint} alt='icon print'/></button>
             <button onClick={handleDownload }><img src={iconDowland} alt='icon dowland'/></button>
             <button onClick={handleShare} ><img src={iconShare} alt='icon share'/></button> 
            </div>

          </div>
            <div className="norma-content">
              {parseXmlToReact(content, idNorma, hoveredArticle, setHoveredArticle, isMicropanelHovered, setIsMicropanelHovered, fixedMicropanelIndex, setFixedMicropanelIndex).map((element, index) => {
                const isTitle = articleTitles[index]?.type === 'titulo';
                return (
                
                  <div
                    key={index}
                    ref={(el) => {
                      if (isTitle) {
                        titleRefs.current[index] = el;
                      } else {
                        articleRefs.current[index] = el;
                      }
                    }}
                    id={articleTitles[index]?.id}
                    className="texto-articulo"
                    onMouseEnter={() => !isMicropanelHovered && setHoveredArticle(articleTitles[index]?.id)}
                    onMouseLeave={() => !isMicropanelHovered && setHoveredArticle(null)}
                  >
                 <br/>
                    {element} 
                  </div>
                  
                );
              })}
            </div>
          </div>
        ) : !error && (

          <Loading message="Buscando.." />
        )}
      </div>
      <ScrollToTopButton />
      <ResultsModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        idNorma={idNorma}
        resultsPerPage={resultsPerPage}
      />
    </div>
  );
};

export default NormContentPage;