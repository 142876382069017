import React from 'react'
import uplFile from '../../assets/icons/upload.svg'
import './contacto.css'

const Contacto = () => {
  return (
    <div className='contact'>
      <div className='contact-container'>
      <div className='contact-form'>
      <div className='contact-title'>
       <h2>¿Necesitas ayuda? Contáctanos</h2>
       <p>Nuestro equipo de soporte está aquí para asistirte con cualquier duda o problema que tengas. Completa el formulario a continuación y nos pondremos en contacto contigo lo antes posible para ofrecerte la ayuda que necesitas.</p>
      </div>
      <form className='form-inputs-contact'>
            <div className='inputGroup-contact'>
              <label htmlFor='name'>Nombre completo</label>
              <input type='text' id='name'  />
            </div>
            <div className='inputGroup-contact'>
              <label htmlFor='email'>Correo electrónico</label>
              <input type='email' id='email' />
            </div>
            <div className='inputGroup-contact'>
              <label htmlFor='name'>Asunto</label>
              <input type='text' id='name'  />
            </div>
            <div className='inputGroup-contact'>
              <label htmlFor='name'>Descripción o consulta</label>
              <textarea name="textarea" rows="10" cols="50">Cuéntanos sobre tu solicitud</textarea>
            </div>
            <div className='inputGroup-contact'>
      <label htmlFor='fileOrLink'>Enlace o archivo (opcional)</label>
      <div className='input-file'>
      <input type='text' id='fileOrLink' placeholder='Ingrese un enlace'/>
     <button type='button'> 
      <img src={uplFile} alt='upload file' />
     </button>
     </div>
    </div>
      <button type='submit' className='contactButton'>Enviar formulario</button>         
      </form>
        
      </div>
      </div>
    </div>
  )
}

export default Contacto