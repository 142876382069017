import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { lastProject } from '../../services/lastProject';
import DataProject from './dataProject';
import iconLastProject from '../../assets/icons/btn-lastlaw.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './LastProjectList.css';

const LastProjectList = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isDataProjectVisible, setIsDataProjectVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await lastProject();
        setData(result.data.data.content);

      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: 'black' }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: 'black' }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3, 
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };
  const onClickProject = (item) => {
    const boletinNumber = item.description.NroBoletin; 
    window.location.href = `https://tramitacion.senado.cl/appsenado/templates/tramitacion/index.php?boletin_ini=${boletinNumber}`;
  };
  

  const onCloseProject = () => {
    setIsDataProjectVisible(false);
  };

  return (
    <>
      {isDataProjectVisible && selectedProject && <DataProject project={selectedProject} onClose={onCloseProject}  allProjectsData={data} />}
      <div className="slider-container">
        <div className='slider-title'>
          <h2>Últimos proyectos publicados</h2>
          <p>Explora los proyectos de ley recientes mediante un resumen preciso y mantente informado sobre futuras normativas.</p>
        </div>
        {error && <p>Error: {error}</p>}
        <Slider {...settings}>
          {data.length > 0 ? (
            data.map((item, index) => (
              <div className="last-project" key={index} onClick={() => onClickProject(item)}>
                <h4 className="title-nroProject">Nro boletín: {item.description.NroBoletin}</h4>
                <p className="title-lastProject">
                  {item.description.TituloProyecto.length > 100 ? 
                    `${item.description.TituloProyecto.substring(0, 100)}...` : 
                    item.description.TituloProyecto}
                </p>
                <div className='footer-lastProject'>
                  <div className='data-project'>
                    <span>Origen: {item.description.Origen}</span>
                    <span>Despacho: {formatDate(item.description.FechaDespacho)}</span>
                  </div>
                  <button 
                    className='btn-arrow-last' 
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickProject(item);
                    }}
                  >
                    <img src={iconLastProject} alt="icon-lastproject" />
                  </button> 
                </div>
              </div>
            ))
          ) : (
            <p>No hay datos disponibles</p>
          )}
        </Slider>
      </div>
    </>
  );
};

export default LastProjectList;
