import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Layout/Header/Header';
import Footer from './components/Layout/Footer/Footer';
import './App.css';
import { AppRoutes } from './routes/routes';

function App() {
  return (
    <div className="App">
 <Router>
     <Header />
       <AppRoutes/>
        <Footer />
        </Router>    
    </div>
  );
}

export default App;