import React, { useState, useEffect, useRef } from 'react';
import './normQuestion.css';
import iconQuestion from '../../../assets/icons/scroll-icon.svg';
import { iaQuestionNorm } from '../../../services/iaQuestionNorm';

export const NormQuestion = ({ content, idNorma, questionUser, setQuestionUser, messages, setMessages }) => {
  const [input, setInput] = useState(questionUser);
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);
  const [uniqueId, setUniqueId] = useState('');
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async () => {
    if (input.trim()) {
      const newMessage = { text: input, sender: 'user' };
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setQuestionUser('');
      setInput('');
      setIsTyping(true);

      try {
        const response = await iaQuestionNorm({ content, question: input, idNorma, uniqueId });

        const explanationContent = response.data.content;
        const newUniqueId = response.uniqueId;

        setMessages(prevMessages => [
          ...prevMessages,
          { text: explanationContent, sender: 'bot' }
        ]);

        if (!uniqueId) {
          setUniqueId(newUniqueId);
        }

        setIsTyping(false);
      } catch (error) {
        setError(error.message);
        console.error('Error sending question:', error.message);
        setMessages(prevMessages => [
          ...prevMessages,
          { text: 'Error al obtener la respuesta de IA', sender: 'bot' }
        ]);
        setIsTyping(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className='norm-question'>
      <div className="chat-messages-question">
        {messages.map((message, index) => (
          <div key={index} className={`chat-message ${message.sender}`}>
            <p>{message.text}</p>
            <div />
          </div>
        ))}
        {isTyping && (
          <div className="chat-typing-question" ref={messagesEndRef} >
            <div className='chat-typing-container'>
              <p>Escribiendo...</p>
            </div>
          </div>
        )}
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <input
          disabled={isTyping}
          onKeyPress={handleKeyPress}
          onChange={(e) => setInput(e.target.value)}
          type="text"
          placeholder="Escribe tu pregunta"
          className="input-question"
          value={input}
        />
        <button className='btn-question' onClick={handleSend} disabled={isTyping}>
          <img src={iconQuestion} alt="icon-question" />
        </button>
      </form>
      {error && <div className='error-message'>{error}</div>}
      <div className='norm-question-footer'>
        <span>Respuesta generada con IA Normativa</span>
      </div>
    </div>
  );
};
