import React from 'react'
import './restorePass.css'

const RestorePass = () => {
  return (
    <>
    <div className='restore'>
        <div className='restore-body'>
        <div className='restore-title'>
          <h3>Restablecer contraseña</h3>
          <p>Hemos enviado un correo electrónico a ho**@normativa.legal con tu código de recuperación de contraseña</p>
        </div>
        <div className='restore-verification'>
          <span>Código de recuperación</span>
          <input type='text' id='verification' placeholder="Ingresar código de recuperación" />
        </div>
        <div className='restore-submit'>
          <button>Continuar</button>
          <div className='restore-support'>
          <span>¿No recibes tu código?</span>
          <a href='/contacto'>
          Contacta a soporte
          </a>
          </div>
        </div>
        </div>
        
    </div>

    </>
  )
}
export default RestorePass