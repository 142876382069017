// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #F6F6F6;
  transition: background 0.3s ease, border-color 0.3s ease;
  border: none;
  border-radius: 96px;
  padding: 12px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  }
  

  .scroll-to-top-button:hover {
    background-color:#E7E7E7 

  }
  
`, "",{"version":3,"sources":["webpack://./src/components/utilities/ScrollTopButton/scrollTopButton.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,wDAAwD;EACxD,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,wCAAwC;EACxC,WAAW;EACX,YAAY;EACZ;;;EAGA;IACE;;EAEF","sourcesContent":[".scroll-to-top-button {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  background-color: #F6F6F6;\n  transition: background 0.3s ease, border-color 0.3s ease;\n  border: none;\n  border-radius: 96px;\n  padding: 12px;\n  cursor: pointer;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  width: 40px;\n  height: 40px;\n  }\n  \n\n  .scroll-to-top-button:hover {\n    background-color:#E7E7E7 \n\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
