import React from 'react';
import { iaChatDocument } from '../../../services/iaChatProject';
import './modals.css';

export const CloseIaProject = ({ onClose, onFinalClose, assistantName, project }) => {
  // const clickClose = async () => {
  //   try {
  //     await iaChatDocument({ project, deleteBot: true });
  //     console.log(`Chat deleted for ${assistantName}`);
  //     onFinalClose();
  //   } catch (error) {
  //     console.error("Error during chat deletion:", error);
  //   }
  // };

  const clickClose = () => {
    onFinalClose();
  };

  const clickCancel = () => {
    onClose(); 
  };

  return (
    <div className='modal-close'>
      <div className='modal-text-alert'>
        <h4>¡Atención!</h4>
        <p>Al cerrar el apartado de últimos proyectos, eliminarás la IA Proyectos creada anteriormente</p>
      </div>
      <div className='modal-footer'>
        <button onClick={clickCancel} className='btn-cancelar-modal'>Cancelar</button>
        <button onClick={clickClose} className='btn-cerrar-modal'>Cerrar</button>
      </div>
    </div>
  );
};

