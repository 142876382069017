import React from 'react';
import './dataProject.css';

export const Votacion = ({ votacion }) => {
  if (!votacion) {
    return <div>No hay datos de votación disponibles.</div>;
  }

  const siVotes = [];
  const noVotes = [];
  const abstencionVotes = [];
  const pareoVotes = [];

  votacion.forEach((detalle) => {
    const { parlamentario, seleccion } = detalle;
    if (seleccion.includes("Si")) {
      siVotes.push(parlamentario);
    } else if (seleccion.includes("No")) {
      noVotes.push(parlamentario);
    } else if (seleccion.includes("Abstencion")) {
      abstencionVotes.push(parlamentario);
    } else if (seleccion.includes("Pareo")) {
      pareoVotes.push(parlamentario);
    }
  });

  const maxLength = Math.max(siVotes.length, noVotes.length, abstencionVotes.length, pareoVotes.length);

  return (
    <div className='table-votacion-container'>
      <div className='table-votacion-header'>
        <table className="table">
          <thead>
            <tr>
              <th className="titles-col" scope="col">Si</th>
              <th className="titles-col" scope="col">No</th>
              <th className="titles-col" scope="col">Abstención</th>
              <th className="titles-col" scope="col">Pareo</th>
            </tr>
          </thead>
          <tbody className="row-data">
            {Array.from({ length: maxLength }).map((_, index) => (
              <tr key={index}>
                <td className="titles-row">{siVotes[index] || '-'}</td>
                <td className="titles-row">{noVotes[index] || '-'}</td>
                <td className="titles-row">{abstencionVotes[index] || '-'}</td>
                <td className="titles-row">{pareoVotes[index] || '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Votacion;
