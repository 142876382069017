// import axios from 'axios';

// const urlApi = process.env.REACT_APP_URL_API;
// const token = process.env.REACT_APP_USER_CREATE_TOKEN;

// export const deleteChat = async () => {


//   const config = {
//     method: 'POST',
//     url: `${urlApi}assistant/senado-documents/${assistantName}`,
//     headers: {
//       'Authorization': token,
//       'Content-Type': 'application/json',
//     },
//     data: {
//         "namespace": assistantName,
//         "chunkSize": 1000,
//         "boletinId": project,
//         "deleteBot": "true"
//     },
//   };

//   try {
//     const response = await axios(config);
//     if (response) {
//         console.log(response)
//       return response.data.taskId;
//     } else {
//       throw new Error('La respuesta no contiene el campo taskId esperado');
//     }
//   } catch (error) {
//     console.error('Hubo un problema al procesar la solicitud:', error.response ? error.response.data : error.message);
//     throw error;
//   }
// };