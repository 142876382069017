import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  name: yup.string().required('*Nombre es requerido'),
  email: yup.string().email('*Email es invalido').required('*Email es requerido'),
  password: yup
    .string()
    .required('*La contraseña es requerida')
    .min(8, '*La contraseña debe tener minimo 8 caracteres'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], '* La contraseña no coincide')
    .required('*Confirme su contraseña'),
});
