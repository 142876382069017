import axios from 'axios';

const urlApi = process.env.REACT_APP_URL_API;
const token = process.env.REACT_APP_USER_CREATE_TOKEN;

export const formSpecialistData = async (formData) => {
  // Mapear selectedNorms para extraer id y description
  const norms = formData.selectedNorms.map(norm => ({
    idNorm: norm.id,
    description: norm.description
  }));

  const data = JSON.stringify({
    companyRut: formData.rut,
    companyFantasyName: formData.fantasyName,
    companyActivity: formData.activity, 
    companyName: formData.razonSocial, 
    companyAddress: formData.direccion,
    companyState: formData.region,
    companyCity: formData.comuna,
    companyCountry: formData.country,
    contactName: formData.name,
    contactEmail: formData.email,
    contactPhone: formData.phone,
    publishLogo: formData.publishLogo,
    publishPhone: formData.publishPhone,
    publishEmail: formData.publishEmail,
    publishWebSite: formData.webSite,
    norms: norms 
  });

  const config = {
    method: 'post',
    url: `${urlApi}specialist/company/create`,
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json'
    },
    data: data
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Error response:', error.response.data);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

export const uploadLogo = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const config = {
    method: 'post',
    url: `${urlApi}specialist/load-file`,
    headers: {
      'Authorization': token,
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  };

  try {
    const response = await axios(config);
    return response.data.data; 
  } catch (error) {
    console.error('Error al cargar el logo:', error);
    throw error;
  }
};