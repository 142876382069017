import React from 'react';
import './Home.css'
import SearchBar from '../../components/SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from 'react-helmet';
import LastProjectList from '../../components/lastProject/LastProjectList';
import LastLawList from '../../components/lastLaw/LastLawList';
import iconFile from '../../assets/icons/icon-file-home.svg'
import iconFileSearch from '../../assets/icons/file-search.svg'
import iconFile2 from '../../assets/icons/icon-file-home2.svg'
import explicacionCard from '../../assets/explicacion-card.svg'
import buscadorCard from '../../assets/buscardor-card.svg'
import especialistasCard from '../../assets/especialistas-card.svg'


const Home = () => {

  const scrollToSearchBar = () => {
    document.getElementById('home').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
    <div className='home' id='home'>
      <Helmet>
        <title>Normativa Legal Chile: Accede a las Leyes Chilenas Actualizadas con IA</title>
        <meta name="description" content="Normativa Legal facilita el acceso a las normativas chilenas más recientes con tecnología de Inteligencia Artificial. Utiliza nuestro buscador avanzado para encontrar leyes del diario oficial, interactuar con artículos y conectar con especialistas. Mantente al día con las leyes de Chile de manera eficiente y precisa." />
      </Helmet>
      <div className='home-container'>
        <div className='home-title'>
        <h1>Encuentra y comprende las Normativas Legales chilenas con IA</h1>
        <p>Bienvenido a Normativa Legal, tu fuente confiable de leyes chilenas actualizadas y herramientas avanzadas de Inteligencia Artificial para su comprensión.</p>
      </div>
      <div id="search-bar">
            <SearchBar />
          </div>
      <div className='title-cards'>
        <h2>Interactúa con artículos y conecta con especialistas</h2>
        <p>Nuestra IA permite una interacción avanzada con los artículos, ofreciendo explicaciones detalladas y respuestas a tus preguntas en tiempo real. Además, te conectamos con especialistas en el área según los artículos.</p>
      </div>
      <div className='cards'>
        <div className='card-left'>
          <div className='title-card'>
         <span className='title-span-card'> <img src={iconFile} alt='img-card'/> La IA Normativa te explica y responde dudas de los artículos</span>
         <h3 className='subtitle'>Interactúa con artículos de tu interés </h3>
        </div>
          <div className='img-card'>
            <img src={explicacionCard} alt='img-card'/>
          </div>
      </div>
      <div className='card-rigth'>
          <div className='title-card'>
          <span className='title-span-card'> <img src={iconFile2} alt='img-card'/>Un directorio de profesionales a tu completa disposición</span>
          <h3 className='subtitle'>Conecta con los especialistas necesarios </h3>
          <h3></h3>
          </div>
          <div className='img-card'>
          <img src={especialistasCard} alt='especialistas-card'/>
        </div>
      </div>

      </div>

      <LastLawList/>
        <div className='cards-especialistas'>
        <div className='card-especialista-left'>
          <div className='title-card-especialista-left'>
         <span className='title-span-card'> <img src={iconFileSearch} alt='img-card'/>Todas leyes actualizadas a tu  entera disposición</span>
         <h3 >Busca normativas  <span>de forma fácil y rápida</span></h3>
         </div>
         <div className='img-card'>
         <img src={buscadorCard} alt='especialista-card' className='especialista-card'/>
         </div>

      </div>
      <div className='card-especialista-rigth'>
          <div className='title-card-especialista-right'>
          <h2>Encuentra leyes chilenas actualizadas con nuestro buscador</h2>
          <span>Nuestro buscador avanzado te permite acceder rápidamente a las normativas chilenas más recientes del diario oficial. Introduce tus términos de búsqueda y descubre leyes, artículos y regulaciones de manera precisa y eficiente, facilitando tu cumplimiento normativo y manteniéndote siempre informado.</span>
          <button onClick={scrollToSearchBar}>Utilizar buscador</button>
        </div>
        </div>
        </div>

      <LastProjectList/>
     </div>
        <div className='preguntas-frecuentes'>
          <div className='title-preguntas'>
            <h2>Preguntas frecuentes</h2>
            <p>Normativa Legal facilita el acceso y la comprensión de las leyes chilenas utilizando tecnología de Inteligencia Artificial. A continuación, respondemos algunas de las preguntas más comunes para ayudarte a aprovechar al máximo nuestra plataforma. 
              Descubre cómo funciona nuestro buscador, cómo puedes interactuar con los artículos normativos y cómo te conectamos con especialistas.</p>
          </div>
        <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>¿Cómo funciona el buscador de Normativa Legal?</Accordion.Header>
        <Accordion.Body>
        Nuestro buscador utiliza tecnología de Inteligencia Artificial para acceder a las normativas chilenas actualizadas del diario oficial. Simplemente ingresa tus términos de búsqueda y encuentra las leyes y artículos que necesitas de manera rápida y precisa.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>¿Qué es la Inteligencia Artificial en Normativa Legal?</Accordion.Header>
        <Accordion.Body>
        La IA en Normativa Legal desglosa y filtra artículos normativos, ofrece explicaciones detalladas y respuestas en tiempo real. Esta tecnología avanzada mejora la comprensión y acceso a la legislación chilena, facilitando tu cumplimiento normativo.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>¿Puedo encontrar especialistas en Normativa Legal?</Accordion.Header>
        <Accordion.Body>
        Sí, Normativa Legal te conecta con especialistas en diversas áreas según los artículos normativos que consultes. Ya sea que necesites asesoría legal, consultoría ambiental u otro tipo de experto, te ayudamos a cumplir con tus requerimientos normativos.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>¿Cómo puedo estar al día con las leyes chilenas recientes?</Accordion.Header>
        <Accordion.Body>
        Nuestro slider de leyes actualizadas te muestra las últimas normativas publicadas en el diario oficial. Mantente informado sobre las leyes chilenas más recientes y asegúrate de estar siempre en conformidad con la legislación vigente.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>¿Qué tipos de normativas puedo encontrar?</Accordion.Header>
        <Accordion.Body>
        En Normativa Legal puedes encontrar una amplia gama de normativas chilenas, incluyendo leyes laborales, medioambientales, de seguridad y más. Nuestro buscador avanzado y nuestra IA te ayudarán a encontrar exactamente lo que necesitas.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>¿Cómo puedo interactuar con los artículos normativos?</Accordion.Header>
        <Accordion.Body>
        Nuestra plataforma permite una interacción avanzada con los artículos normativos. Puedes obtener explicaciones detalladas, hacer preguntas y recibir respuestas en tiempo real, lo que facilita una comprensión profunda y precisa de la legislación.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

        </div>
        <div className='banner'>
        <div className='banner-content'>
          <h2>Optimiza tu acceso y entendimiento de las normativas con nuestra plataforma</h2>
          <p>Únete a Normativa Legal hoy y simplifica tu acceso y comprensión de las leyes chilenas. Con nuestra IA y conexión con especialistas, estar al tanto de las normativas nunca ha sido tan fácil</p>
          <a href=''> <button className='btn-register-home'>Registrarse ahora</button></a>
        
        </div>
      </div>

    
    </div>

    </>
  );
}

export default Home;