import axios from 'axios';

const token = process.env.REACT_APP_USER_CREATE_TOKEN;
const apiUrl = process.env.REACT_APP_URL_API;

const JurisprudenceService = {
  getCorteSuprema: async function(id, page, resultsPerPage) {
    const config = {
      method: 'get',
      url: `${apiUrl}normativa/jurisprudence/corte-suprema/${id}/${page}/${resultsPerPage}`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'X-API-Key': token
      }
    };

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.error('Error fetching Corte Suprema data:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getTribunalAmbiental: async function(id, page, resultsPerPage) {
    const config = {
      method: 'get',
      url: `${apiUrl}normativa/jurisprudence/tribunal-ambiental/${id}/${page}/${resultsPerPage}`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'X-API-Key': token
      }
    };

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.error('Error fetching Tribunal Ambiental data:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getContraloria: async function (id) {
    const config = {
      method: 'get',
      url: `${apiUrl}normativa/jurisprudence/contraloria/${id}`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'X-API-Key': token
      }
    };

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.error('Error fetching Contraloria data:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getDictamen: async function (norm, year) {
    const config = {
      method: 'get',
      url: `${apiUrl}normativa/jurisprudence/dictamen/${norm}/${year}`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'X-API-Key': token
      }
    };

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.error('Error fetching Dictamen data:', error.response ? error.response.data : error.message);
      throw error;
    }
  }
};

export default JurisprudenceService;