import React, { useState } from 'react';
import isotipo from '../../../assets/isotipo.svg';
import logoVerified from '../../../assets/icons/icon-verified.svg';
import iconTel from '../../../assets/icons/icon-phone.svg';
import iconEmail from '../../../assets/icons/icon-mail.svg';
import iconLink from '../../../assets/icons/icon-link.svg';

export const CardSpecialist = ({ specialist, idNorm }) => {

  return (
    <div className='specialist-card'>
      <div className="specialist-card-header">
        <div className="specialist-card-header-title">
          <img src={specialist.publish.logo} alt="logo Card" />
          <p>{specialist.company.fantasyName}</p>
        </div>
        <div className='specialist-card-header-verified'>
          <img src={logoVerified} alt="logo verified" />
          <p>Verificado</p>
        </div>
      </div>

      <div className='specialist-card-description'>
        {specialist.norms
          .filter(norm => norm.idNorm === idNorm) // Filtramos solo la norma que coincide con idNorm
          .map((norm, index) => (
            <p key={index}>{norm.description}</p> // Renderizamos la descripción si coincide
          ))}
      </div>

      <div className='specialist-card-footer'>
        <p>Contactar:</p>
        <div className='specialist-card-footer-contact'>
          <div className='contact-specialist'>
          <a href={`https://api.whatsapp.com/send/?phone=${specialist.publish.phone}`} target="_blank" rel="noopener noreferrer">
              <img src={iconTel} alt="tel" />
            </a>
          </div>
          <div className='contact-specialist'>
          <a href={`mailto:${specialist.publish.email}`}>
          <img src={iconEmail} alt="correo" />
        </a>

          </div>
          <div className='contact-specialist'>
            <a href={specialist.publish.webSite} target="_blank" rel="noopener noreferrer">
              <img src={iconLink} alt="enlace" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
