// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress{
    background-color: #F6F6F6;
    height: 8px;
    margin-top: 16px;
}
.progress-bar{
    background-color: #E84545;
}
.progressBar-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70%;
}
.progressBar-title{
    text-align: left;
    color: rgba(37, 37, 37, 1);
}
.progressBar-title{
    margin: 0;
}
.progressBar-title h4{
    color: rgba(37, 37, 37, 1);
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/utilities/ProgressBar/progressBar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,0BAA0B;AAC9B;AACA;IACI,SAAS;AACb;AACA;IACI,0BAA0B;IAC1B,SAAS;AACb","sourcesContent":[".progress{\n    background-color: #F6F6F6;\n    height: 8px;\n    margin-top: 16px;\n}\n.progress-bar{\n    background-color: #E84545;\n}\n.progressBar-container{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 70%;\n}\n.progressBar-title{\n    text-align: left;\n    color: rgba(37, 37, 37, 1);\n}\n.progressBar-title{\n    margin: 0;\n}\n.progressBar-title h4{\n    color: rgba(37, 37, 37, 1);\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
