// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.specialist{
    background: white;
    display: flex;
    height: 320px;
    padding: 56px 16px 16px 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
.specialist-container{
    display: flex;
    width: 450px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.specialist-title{
    font-weight: 600;
    text-align: center !important;
    padding: 0 !important;
    margin-top: 4px;
    font-size: 16px !important;
}
.specialist-subTitle{
    white-space: unset !important;
    text-align: center !important;
    padding: 0 !important;
    margin-top: 16px !important;
    font-size: 16px !important;
}
.specialist-footer{
    margin-bottom: 16px;
}
.specialist-footer span{
    color: rgba(136, 136, 136, 1);
}
.specialist-footer a {
    color: rgba(232, 69, 69, 1);
    text-decoration: underline;
}
@media (max-width: 768px) {
    .specialist {
        min-height: 320px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px
    }
    .specialist-container {
        width: 100% !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/utilities/Specialist/specialist.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,aAAa;IACb,4BAA4B;IAC5B,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,+BAA+B;IAC/B,8BAA8B;AAClC;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;;AAEvB;AACA;IACI,gBAAgB;IAChB,6BAA6B;IAC7B,qBAAqB;IACrB,eAAe;IACf,0BAA0B;AAC9B;AACA;IACI,6BAA6B;IAC7B,6BAA6B;IAC7B,qBAAqB;IACrB,2BAA2B;IAC3B,0BAA0B;AAC9B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,6BAA6B;AACjC;AACA;IACI,2BAA2B;IAC3B,0BAA0B;AAC9B;AACA;IACI;QACI,iBAAiB;QACjB,8BAA8B;QAC9B;IACJ;IACA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".specialist{\n    background: white;\n    display: flex;\n    height: 320px;\n    padding: 56px 16px 16px 16px;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    align-self: stretch;\n    border-bottom-right-radius: 6px;\n    border-bottom-left-radius: 6px;\n}\n.specialist-container{\n    display: flex;\n    width: 450px !important;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n}\n.specialist-title{\n    font-weight: 600;\n    text-align: center !important;\n    padding: 0 !important;\n    margin-top: 4px;\n    font-size: 16px !important;\n}\n.specialist-subTitle{\n    white-space: unset !important;\n    text-align: center !important;\n    padding: 0 !important;\n    margin-top: 16px !important;\n    font-size: 16px !important;\n}\n.specialist-footer{\n    margin-bottom: 16px;\n}\n.specialist-footer span{\n    color: rgba(136, 136, 136, 1);\n}\n.specialist-footer a {\n    color: rgba(232, 69, 69, 1);\n    text-decoration: underline;\n}\n@media (max-width: 768px) {\n    .specialist {\n        min-height: 320px;\n        border-bottom-left-radius: 8px;\n        border-bottom-right-radius: 8px\n    }\n    .specialist-container {\n        width: 100% !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
