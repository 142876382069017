import React, { useState, useEffect, useLayoutEffect } from 'react';
import Modal from 'react-modal';
import JurisprudenceService from '../../services/jurisprudenceService';
import './ResultsModal.css';
import Loading from '../../components/utilities/Loading/loading';
import errorIcon from '../../assets/icons/not-results.svg';


Modal.setAppElement('#root');

const ResultsModal = ({ isOpen, onRequestClose, idNorma, initialResultsPerPage = 10 }) => {
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(initialResultsPerPage);
  const [selectedTab, setSelectedTab] = useState('CorteSuprema');
  const [expandedYears, setExpandedYears] = useState({});
  const [dictamenDetail, setDictamenDetail] = useState(null);
  const [selectedNorm, setSelectedNorm] = useState({ norm: null, year: null });
  const [showDictamenText, setShowDictamenText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const linkStyle = {
    color: '#454545',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '22px',
  };

  const arrowIconTSStyle = {
    paddingLeft: '8px',
  };

  const videoLinkStyle = {
    color: '#454545',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '22px',
    textDecorationLine: 'underline',
  };

  useEffect(() => {
    const fetchResults = async () => {
      setIsLoading(true);
      setError(null); // Restablecer el error antes de la solicitud
      try {
        let data;
        if (selectedTab === 'CorteSuprema') {
          data = await JurisprudenceService.getCorteSuprema(idNorma, currentPage, resultsPerPage);
        } else if (selectedTab === 'TribunalAmbiental') {
          data = await JurisprudenceService.getTribunalAmbiental(idNorma, currentPage, resultsPerPage);
        } else if (selectedTab === 'Contraloria') {
          data = await JurisprudenceService.getContraloria(idNorma);
        }
  
        console.log('Datos recibidos:', data);
  
        if (selectedTab === 'Contraloria') {
          // Verificación de resultados para Contraloría
          if (data && data.data && Array.isArray(data.data)) {
            if (data.data.length > 1) {
              // Más de un resultado significa que hay resultados válidos
              setResults(data.data);
              setError(null);
              setTotalResults(data.data.length);
            } else {
              // Un solo resultado significa que debe mostrar el error
              console.log('Un solo objeto encontrado, mostrando error');
              setError('No existe jurisprudencia');
              setResults([]);
              setTotalResults(0);
            }
          } else {
            // Mostrar error si no hay datos válidos
            console.log('No hay datos para Contraloría');
            setError('No existe jurisprudencia');
            setResults([]);
            setTotalResults(0);
          }
        } else {
          // Verificación general para otras pestañas
          const fetchedResults = data.data.normasList || data.data.content || data.data || [];
          if (!fetchedResults || fetchedResults.length === 0) {
            console.log('No hay resultados para las otras pestañas');
            setError('No existe jurisprudencia');
            setResults([]);
            setTotalResults(0);
          } else {
            setResults(Array.isArray(fetchedResults) ? fetchedResults : []);
            setTotalResults(data.data.total || data.data.length || 0);
          }
        }
      } catch (error) {
        console.error('Error al obtener los datos:', error);
        if (error.response && error.response.status === 404) {
          setError('No existe jurisprudencia');
        } else {
          setError('Ha ocurrido un error al obtener los datos. Por favor, intenta nuevamente.');
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    if (isOpen) {
      fetchResults();
    }
  }, [isOpen, idNorma, resultsPerPage, currentPage, selectedTab]);
  
  
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const formatText = (text) => {
    return text
      .toLowerCase()
      .replace(/(^|\s)\S/g, (L) => L.toUpperCase());
  };

  const toggleYear = (year) => {
    setExpandedYears((prevExpandedYears) => ({
      ...prevExpandedYears,
      [year]: !prevExpandedYears[year],
    }));
  };

  const handleNormItemClick = async (norm, year) => {
    if (selectedNorm.norm === norm && selectedNorm.year === year) {
      // Si el mismo item ya está seleccionado, se cierra el detalle
      setSelectedNorm({ norm: null, year: null });
      setDictamenDetail(null);
    } else {
      // Si es un nuevo item, se abre el detalle
      try {
        const data = await JurisprudenceService.getDictamen(norm, year);
        setDictamenDetail(data.data);
        setSelectedNorm({ norm, year });
        setShowDictamenText(false);
      } catch (error) {
        setError('Error fetching dictamen data');
        console.error("Error fetching dictamen data:", error);
      }
    }
  };

  const toggleDictamenText = () => {
    setShowDictamenText((prevState) => !prevState);
  };

  const startItem = (currentPage - 1) * resultsPerPage + 1;
  const endItem = Math.min(currentPage * resultsPerPage, totalResults);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Resultados"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="header-jurisprudence">
        <div className="tabs">
          <div className={`tab ${selectedTab === 'CorteSuprema' ? 'active' : ''}`} onClick={() => {setSelectedTab('CorteSuprema'); setCurrentPage(1);}}>
            Corte Suprema
          </div>
          <div className={`tab ${selectedTab === 'Contraloria' ? 'active' : ''}`} onClick={() => { setSelectedTab('Contraloria'); setCurrentPage(1); }}>
            Contraloría
          </div>
          <div className={`tab ${selectedTab === 'TribunalAmbiental' ? 'active' : ''}`} onClick={() => { setSelectedTab('TribunalAmbiental'); setCurrentPage(1); }}>
            Tribunal Ambiental
          </div>
        </div>
        <button onClick={onRequestClose} className="close-button">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4L4 12" stroke="#454545" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 4L12 12" stroke="#454545" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      </div>
      {isLoading ? (
        <div className="loading-container-jurisprudence">
          <Loading /> Buscando jurisprudencia...
        </div>
        ) : error ? (
          <div className="error-message-jurisprudence">
            <div className="error-body">
              <div className="title-error">
                <img src={errorIcon} alt="icono sin resultados" />
                <p>No existe jurisprudencia.</p>
              </div>
              <div className="subtitle-error">
                <p>Lo sentimos, esta normativa no cuenta con la jurisprudencia solicitada.</p>
              </div>
            </div>
          </div>
        ) : (
        <div className="inner-content">
          {selectedTab === 'Contraloria' && results[0] && results[0].warning && (
            <div className='warning-container'>
              <div
                className="contraloria-warning"
                style={{
                  backgroundColor: '#FFF8ED',
                  padding: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  maxWidth: '597px',
                  maxHeight: '36px',
                  borderRadius: '6px',
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.4866 12L9.15329 2.66665C9.037 2.46146 8.86836 2.29078 8.66457 2.17203C8.46078 2.05329 8.22915 1.99072 7.99329 1.99072C7.75743 1.99072 7.52579 2.05329 7.322 2.17203C7.11822 2.29078 6.94958 2.46146 6.83329 2.66665L1.49995 12C1.38241 12.2036 1.32077 12.4346 1.32129 12.6697C1.32181 12.9047 1.38447 13.1355 1.50292 13.3385C1.62136 13.5416 1.79138 13.7097 1.99575 13.8259C2.20011 13.942 2.43156 14.0021 2.66662 14H13.3333C13.5672 13.9997 13.797 13.938 13.9995 13.8208C14.202 13.7037 14.3701 13.5354 14.487 13.3327C14.6038 13.1301 14.6653 12.9002 14.6653 12.6663C14.6652 12.4324 14.6036 12.2026 14.4866 12Z"
                    stroke="#454545"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 6V8.66667"
                    stroke="#454545"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 11.3333H8.00667"
                    stroke="#454545"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span
                  style={{
                    color: '#454545',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                  }}
                >
                  {results[0].warning}
                </span>
              </div>
            </div>
          )}
          <div className="filters" style={selectedTab === 'TribunalAmbiental' ? { justifyContent: 'flex-end' } : {}}>
            {selectedTab === 'CorteSuprema' && (
              <div className="filter-dropdowns">
                <select className="filter-dropdown">
                  <option>Sala</option>
                </select>
                <select className="filter-dropdown">
                  <option>Año de sentencia</option>
                </select>
              </div>
            )}
            {selectedTab !== 'Contraloria' && (
              <div className="results-per-page-container">
                <select
                  className="results-dropdown"
                  onChange={(e) => { setCurrentPage(1); setResultsPerPage(Number(e.target.value)); }}
                  value={resultsPerPage}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <div className="pagination">
                  <button className="pagination-button" onClick={handlePreviousPage}>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" fill="#FFFFFF" />
                      <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" stroke="#E7E7E7" />
                      <path d="M16 18L12 14L16 10" stroke="#454545" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                  <span className="page-range">{startItem} - {endItem}</span>
                  <button className="pagination-button" onClick={handleNextPage}>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" fill="#FFFFFF" />
                      <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" stroke="#E7E7E7" />
                      <path d="M12 18L16 14L12 10" stroke="#454545" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                  <span className="total-results">de {totalResults}</span>
                </div>
              </div>
            )}
          </div>
          <div className="results-container">
            {selectedTab === 'Contraloria' ? (
              results[0] && results[0].project?.map((proj, idx) => (
                <div key={idx}>
                  <div
                    className="year-item"
                    onClick={() => toggleYear(proj.year)}
                  >
                    <h3
                      className="result-title"
                      style={{
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '22px',
                        transition: 'color 0.2s',
                      }}
                    >
                      Año {proj.year}
                    </h3>
                    <svg
                      className="arrow-icon-contraloria"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ transition: 'stroke 0.2s' }}
                    >
                      <path
                        d="M4 6L8 10L12 6"
                        className="arrow-path"
                        stroke="#454545"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  {expandedYears[proj.year] && (
                    <div className="norms-list">
                      {proj.norm.map((norm, normIdx) => (
                        <React.Fragment key={normIdx}>
                          <div
                            className="norm-item"
                            style={{
                              marginBlock: '16px',
                              color: '#454545',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: '500',
                              lineHeight: '22px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleNormItemClick(norm, proj.year)}
                          >
                            <span style={{ paddingRight: '8px', paddingLeft: '8px' }}>•</span> Dictamen {norm}, año {proj.year}
                          </div>
                          {/* Mostrar el resultado del dictamen justo debajo del norm-item correspondiente */}
                          {dictamenDetail && selectedNorm.norm === norm && selectedNorm.year === proj.year && (
                            <div
                              className="dictamen-detail"
                              style={{
                                padding: '16px',
                                border: '1px solid #f6f6f6',
                                borderRadius: '8px',
                                marginBottom: '15px',
                                backgroundColor: '#f9f9f9',
                              }}
                            >
                              <p>Nro. Dictamen: <strong>{dictamenDetail.idNorm}</strong></p>
                              <p>Fecha: <strong>{dictamenDetail.date}</strong> </p>
                              <p>Fuentes legales: <strong>{dictamenDetail.sources}</strong></p>
                              <div className="ver-texto-container" onClick={toggleDictamenText}>
                                <span className="ver-texto-link" style={linkStyle}>Ver dictamen</span>
                                <svg className="arrow-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 9L12 15L18 9" stroke="#454545" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </div>
                              {showDictamenText && (
                                <div className="text-content-dictamen" dangerouslySetInnerHTML={{ __html: dictamenDetail.text }} />
                              )}
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </div>
              ))
            ) : (
              Array.isArray(results) && results.map((result, index) => (
                <div key={index}>
                  <h3 className="result-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {index + startItem}.- rol: {result.rol_s || result.idNorma}
                    {selectedTab === 'CorteSuprema' && (
                      ` - ${new Date(result.fecha_sentencia_dt || result.fecha_sentencia).toLocaleDateString('es-ES', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                      }).replace(/\./g, '').toUpperCase()}`
                    )}
                    <svg className="arrow-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 9L12 15L18 9" stroke="#454545" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </h3>
                  <div className="result-item">
                    {selectedTab === 'CorteSuprema' ? (
                      <>
                        <p>Sala: <strong>{result.sala_s ? formatText(result.sala_s) : ''}</strong></p>
                        <p>Recurso: <strong>{result.tipo_recurso_s ? formatText(result.tipo_recurso_s) : ''}</strong></p>
                        <p>Caratulado: <strong>{result.caratulado_s ? formatText(result.caratulado_s) : ''}</strong></p>
                        <p>Resultado: <strong>{result.resultado_recurso_s ? formatText(result.resultado_recurso_s) : ''}</strong></p>
                        <div className="ver-texto-container" onClick={() => setResults((prevResults) =>
                          prevResults.map((r, i) =>
                            i === index ? { ...r, showText: !r.showText } : r
                          )
                        )}>
                          <span className="ver-texto-link" style={linkStyle}>Ver texto</span>
                          <svg className="arrow-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 9L12 15L18 9" stroke="#454545" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                        {result.showText && <div className="text-content" dangerouslySetInnerHTML={{ __html: result.text }} />}
                      </>
                    ) : selectedTab === 'TribunalAmbiental' ? (
                      <>
                        <p>Carátula: <strong>{result.caratula_s ? formatText(result.caratula_s) : ''}</strong></p>
                        <p>Materia(s): <strong>{result.materias_ss ? formatText(result.materias_ss.join(', ')) : ''}</strong></p>
                        <p>Submateria(s): <strong>{result.submaterias_ss ? formatText(result.submaterias_ss.join(', ')) : ''}</strong></p>
                        {result.audiencias_ss && result.audiencias_ss.length > 0 && (
                          <p>Audiencias: <a href={result.audiencias_ss[0]} target="_blank" rel="noopener noreferrer" style={videoLinkStyle}>Ver video de la audiencia</a></p>
                        )}
                        <div className="ver-texto-container" style={{ justifyContent: 'flex-end' }}>
                          <span className="ver-texto-link" style={linkStyle}>
                            <a href={result.url_expediente_s} target="_blank" rel="noopener noreferrer">Ver expediente</a>
                          </span>
                          <svg className="arrow-icon-ts" width="14" height="16" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={arrowIconTSStyle}>
                            <path d="M1 9L5 5L1 1" stroke="#454545" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                      </>
                    ) : (
                      <>
                        <p>Norma: <strong>{result.norma ? result.norma.join(', ') : ''}</strong></p>
                        <p>Aviso: <strong>{result.warning || ''}</strong></p>
                        <p>Proyectos:</p>
                        {result.project && result.project.map((proj, idx) => (
                          <div key={idx}>
                            <p><strong>Año:</strong> {proj.year}</p>
                            <p><strong>Normas:</strong> {proj.norm.join(', ')}</p>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ResultsModal;