import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { lastLaw } from '../../services/lastLaw';
import iconLastLaw from '../../assets/icons/btn-lastlaw.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './LastLawList.css';

const LastLawList = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await lastLaw();
        setData(result.data || []);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);
 

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: 'black' }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: 'black' }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const toCapitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const capitalizeOrganismo = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => (word === 'de' || word === 'y' || word === 'del' ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join(' ');
  };

  const handleButtonClick = (e, idNorma) => {
    e.stopPropagation();
    window.open(`/chile/${idNorma}`, '_blank');
  };

  const handleCardClick = (idNorma) => {
    window.open(`/chile/${idNorma}`, '_blank');
  };

  return (
    <div className="slider-container">
      <div className="slider-title">
        <h2>Últimas leyes publicadas</h2>
        <p>
          Mantente al día con las normativas recientes. Nuestra plataforma te
          muestra las últimas leyes publicadas para que estés informado.
        </p>
      </div>
      {error && <p>Error: {error}</p>}
      <Slider {...settings}>
        {data.length > 0 ? (
          data.map((item, index) => (
            <div className="last-law" key={index} onClick={() => handleCardClick(item.idNorma)}>
              <div className=''>
                <h4>{item.tipos_numeros.tipo_numero.compuesto}</h4>
                <p className="law-last-title">
                  {item.titulo.length > 100
                    ? `${toCapitalize(item.titulo.substring(0, 100))}...`
                    : toCapitalize(item.titulo)}
                </p>
              </div>
              <div className="footer-lastLaw">
                <div className="data-lastLaw">
                  <span>{formatDate(item.fecha_publicacion)}</span>
                  <span>{capitalizeOrganismo(item.organismos.organismo)}</span>
                </div>
                <button className="btn-arrow-last" onClick={(e) => handleButtonClick(e, item.idNorma)}>
                  <img src={iconLastLaw} alt="icon-lastlaw" />
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No hay datos disponibles</p>
        )}
      </Slider>
    </div>
  );
};

export default LastLawList;
