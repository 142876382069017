import React, { useEffect, useState } from 'react';
import './specialistModalSpecialist.css';
import iconCode from '../../../assets/icons/close-menuanchor.svg';
import { CardSpecialist } from './cardSpecialist';
import { specialistList } from '../../../services/specialistList';
import Loading from '../Loading/loading';


export const SpecialistmodalList = ({ idNorm }) => {
  const [showCloseModal, setShowCloseModal] = useState(true);
  const [specialists, setSpecialists] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true); 


  const handleShowCloseModal = () => {
    setShowCloseModal(false);
  };

  useEffect(() => {
    const listSpecialist = async (idNorm) => {
      setIsLoading(true); 
      try {
        const specialistData = await specialistList(idNorm);
        if (Array.isArray(specialistData)) {
          setSpecialists(specialistData);
        } else if (specialistData.data && Array.isArray(specialistData.data)) {
          setSpecialists(specialistData.data); 
        } else {
          throw new Error("Formato de datos incorrecto");
        }
      } catch (error) {
        console.error('Error al encontrar especialistas:', error);
        setErrorMessage(`Hubo un error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (idNorm) {
      listSpecialist(idNorm);
    }
  }, [idNorm]); 

  return (
    <>
      {showCloseModal && (
        <div className="modal-overlay">
          <div className='modal-specialist'>
            <div className='specialist'>
              <div className="specialist-header">
                <h4>Especialistas en la Norma</h4>

                <button className="close-modal" onClick={handleShowCloseModal} >
                  <img src={iconCode} alt="icon Code" />
                </button>
              </div>
              <div className='specialist-container'>
                {isLoading ? (
                  <Loading/>
                ) : specialists.length > 0 ? (
                  specialists.map((specialist) => (
                    <CardSpecialist key={specialist.id} specialist={specialist} idNorm={idNorm} />
                  ))
                ) : (
                  <p>No hay especialistas disponibles.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
